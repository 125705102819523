/// <reference types="google.maps" />
import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { MenuItem, SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Lightbox } from 'ngx-lightbox';

import { CoursesControllerProxy, GolfersControllerProxy } from '../../shared/server-proxies';
import { CourseMapComponent, GolfClubShotDispersion, yardsBetween } from '../../shared/courses/map';
import { SessionService } from '../../shared/auth/session.service';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import LatLng = google.maps.LatLng;
import Point = google.maps.Point;

import {
    greenMarkerIcon,
    locationMarkerIcon,
    measuringToolMarkerIcon,
    shotDispersionToolMarkerIcon,
    targetMarkerIcon,
    warning1MarkerIcon,
    warning2MarkerIcon,
    warning3MarkerIcon,
    getTeeMarkerIcon
} from '../../shared/courses/map/map-marker-icons';

import {
    ModelsCoreCoursesCourseMapMarker,
    ModelsCoreCoursesHoleHistory,
    ModelsCoreCoursesHoleImageTypes,
    ModelsCoreCoursesMapMarkers,
    ModelsCoreGolfersGolfClub,
    ModelsCoreGolfersGolfClubCategories,
    ModelsCoreCoursesCourse,
    ModelsCoreCoursesCourseMapArea,
    ModelsCoursesWhichTeeTeeSummary,
    ModelsWebApiCoursesSaveCourseMapAreaModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-course-strategy',
    templateUrl: './course-strategy.component.html',
    styleUrls: [
        './course-strategy.component.scss'
    ],
    standalone: false
})
export class CourseStrategyComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private golfersProxy: GolfersControllerProxy,
        private currentRoute: ActivatedRoute,
        private currentGolfer: CurrentGolferService,
        private session: SessionService,
        private lightbox: Lightbox) {
        super();
    }

    @ViewChild(CourseMapComponent) map: CourseMapComponent;
    @ViewChildren(MessagesComponent) messageComponents: QueryList<MessagesComponent>;
    isSavingMap = false;
    isSavingNotes = false;
    menuItems: MenuItem[];
    course: ModelsCoreCoursesCourse;
    currentMapArea: ModelsCoreCoursesCourseMapArea;
    courseTees: ModelsCoursesWhichTeeTeeSummary[];
    markerIcons = {
        greenMarkerIcon,
        locationMarkerIcon,
        measuringToolMarkerIcon,
        shotDispersionToolMarkerIcon,
        targetMarkerIcon,
        warning1MarkerIcon,
        warning2MarkerIcon,
        warning3MarkerIcon,
        getTeeMarkerIcon
    };
    currentHoleAllTimeHistory: ModelsCoreCoursesHoleHistory;
    currentHoleMostRecentYearHistory: ModelsCoreCoursesHoleHistory;
    mostRecentYearPlayed: number;
    showTeeShotDecisionTree = false;
    teeFilters: SelectItem[] = [];
    holeImageTypes: SelectItem[] = [
        { label: 'Select One', value: -1 },
        { label: 'Greens Book: Tee Shot', value: ModelsCoreCoursesHoleImageTypes.GreensBookTeeShot },
        { label: 'Greens Book: Green', value: ModelsCoreCoursesHoleImageTypes.GreensBookGreen },
        { label: 'Greens Book: Legend', value: ModelsCoreCoursesHoleImageTypes.GreensBookLegend }
    ];
    filteredTeeId = 0;
    golfClubs: ModelsCoreGolfersGolfClub[] = [];
    selectedGolfClub: ModelsCoreGolfersGolfClub;
    golfClubShotDispersion: GolfClubShotDispersion;
    showTeeToGreenLine = true;
    addedShotDispersionTool = false;
    addingHoleImage = false;
    holeImageTypeId: number;
    token: string;
    private yearsPlayed: number[];
    private holeHistory: { [key: number]: ModelsCoreCoursesHoleHistory[] };
    private allTimeHistoryForAllHoles: ModelsCoreCoursesHoleHistory[];
    private courseId: number;
    private onMapMarkerDroppedHandler: (point: Point) => void;

    get imageUploadUrl() {
        return this.currentMapAreaHasHole
            ? `/api/courses/${this.courseId}/holes/${this.currentMapArea.hole.holeId}/images?HoleImageTypeId=${this.holeImageTypeId}`
            : undefined;
    }

    ngOnInit() {
        this.token = this.session.getToken();
        this.loadDrivingDistance();
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.courseId = parseInt(params['courseId'], 10);
                    this.loadCourseTees();

                    const courseMapObservable = this.loadCourseMap();
                    const courseHistoryObservable = this.loadCourseHistory();

                    forkJoin([courseMapObservable, courseHistoryObservable])
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.loadHoleHistory();
                            });
                });
    }

    get showHistoricalStats() {
        return this.currentMapAreaHasHole
            && this.currentHoleAllTimeHistory
            && this.currentHoleMostRecentYearHistory
            && !this.showTeeShotDecisionTree;
    }

    get canShowTeeShotDecisionTree() {
        return this.addedShotDispersionTool && this.currentMapAreaHasHole && this.currentMapArea.hole.par > 3;
    }

    get currentMapAreaHasHole() {
        return this.currentMapArea && this.currentMapArea.hole && this.currentMapArea.hole.holeId > 0;
    }

    saveMap() {
        this.isSavingMap = true;

        const mapMessages = this.messageComponents.first;
        const data = this.map.getUpdateModel();

        this.coursesProxy.saveCourseMapArea(this.courseId, this.currentMapArea.courseMapAreaId, data)
            .pipe(
                finalize(
                    () => {
                        this.isSavingMap = false;
                        mapMessages.clearInMilliseconds(5000);
                }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    mapMessages.showSuccess('Map has been saved.');
                    this.copyModelToCurrentMapArea(data);
                    this.currentMapArea.courseMapAreaId = response.body.courseMapAreaId;
                },
                error => {
                    mapMessages.showError(`An error occurred. (${error.status})`);
                });
    }

    saveNotes() {
        if(!this.currentMapAreaHasHole) {
            return;
        }

        this.isSavingNotes = true;

        const notesMessages = this.messageComponents.last;
        this.coursesProxy.saveHoleNotes(this.courseId, this.currentMapArea.hole.holeId, { notes: this.currentMapArea.notes })
            .pipe(
                finalize(
                    () => {
                        this.isSavingNotes = false;
                        notesMessages.clearInMilliseconds(5000);
                }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    notesMessages.showSuccess('Notes have been saved.');
                },
                error => {
                    notesMessages.showError(`An error occurred. (${error.status})`);
                });
    }

    addTeeMarker(tee: ModelsCoursesWhichTeeTeeSummary, point?: Point) {
        this.map.addTeeMarker(tee, point);
    }

    addGreenMarker(point?: Point) {
        this.map.addGreenMarker(point);
    }

    addYardageMarker(point?: Point) {
        this.map.addYardageMarker(point);
    }

    addLocationModifierMarker(modifier: number, point?: Point) {
        this.map.addLocationModifierMarker(modifier, point);
    }

    addTeeShotTargetMarker(point?: Point) {
        this.map.addTeeShotTargetMarker(point);
    }

    addMeasuringTool(point?: Point) {
        this.map.addMeasuringTool(point);
    }

    addShotDispersionTool(point?: Point) {
        this.map.addShotDispersionTool(point);
        this.addedShotDispersionTool = true;
    }

    onTeeFilterChanged() {
        this.map.filteredTeeId = this.filteredTeeId;
        this.inferGolfClubFromHoleDistance();
    }

    onGolfClubSelected(e: any) {
        this.setShotDispersionFromGolfClub(e.option);
    }

    shotDispersionToolDeleted() {
        this.addedShotDispersionTool = false;
        this.showTeeShotDecisionTree = false;
    }

    onMapMarkerDropped(e: DragEvent) {
        if(this.onMapMarkerDroppedHandler) {
            const point = new Point(e.pageX, e.pageY);
            this.onMapMarkerDroppedHandler(point);
            this.onMapMarkerDroppedHandler = undefined;
        }
    }

    onTeeMarkerDragStart(tee: ModelsCoursesWhichTeeTeeSummary) {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addTeeMarker(tee, point);
        };
    }

    onGreenMarkerDragStart() {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addGreenMarker(point);
        };
    }

    onYardageMarkerDragStart() {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addYardageMarker(point);
        };
    }

    onLocationModifierMarkerDragStart(modifier: number) {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addLocationModifierMarker(modifier, point);
        };
    }

    onTeeShotTargetMarkerDragStart() {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addTeeShotTargetMarker(point);
        };
    }

    onMeasuringToolDragStart() {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addMeasuringTool(point);
        };
    }

    onShotDispersionToolDragStart() {
        this.onMapMarkerDroppedHandler = (point) => {
            this.addShotDispersionTool(point);
        };
    }

    getImageUploadHeaders() {
        const headers = new HttpHeaders();
        const token = this.session.getToken();

        if(token) {
            headers.append('Authorization', `Bearer ${token}`);
        }

        return headers;
    }

    onImageUploaded(e: any) {
        this.currentMapArea.holeImages = e.originalEvent.body;
        this.addingHoleImage = false;
        this.holeImageTypeId = -1;
    }

    viewImage(index: number) {
        const images = this.currentMapArea.holeImages.map(holeImage => {
           return {
               src: `/api/courses/holes/images/${holeImage.holeImageId}?access_token=${this.token}`,
               caption: `${holeImage.fileName} - ${holeImage.formattedFileSize}`,
               thumb: undefined,
               downloadUrl: undefined
           };
        });

        this.lightbox.open(images, index);
    }

    private copyModelToCurrentMapArea(model: ModelsWebApiCoursesSaveCourseMapAreaModel) {
        this.currentMapArea.heading = model.heading;
        this.currentMapArea.latitude = model.latitude;
        this.currentMapArea.longitude = model.longitude;
        this.currentMapArea.tilt = model.tilt;
        this.currentMapArea.zoom = model.zoom;
        this.currentMapArea.courseMapMarkers = [];

        model.courseMapMarkers.forEach(
            courseMapMarker => {
                const newMarker: ModelsCoreCoursesCourseMapMarker = {
                    latitude: courseMapMarker.latitude,
                    longitude: courseMapMarker.longitude,
                    mapMarker: courseMapMarker.mapMarker,
                    tee: undefined
                };

                const tee = courseMapMarker.teeId ? this.courseTees.find(t => t.teeId === courseMapMarker.teeId) : undefined;

                if(tee) {
                    newMarker.tee = {
                        teeId: tee.teeId,
                        name: tee.name,
                        cssClassName: tee.cssClassName,
                        rating: undefined,
                        slope: undefined,
                        yards: undefined,
                        displayText: undefined
                    };
                }

                this.currentMapArea.courseMapMarkers.push(newMarker);
            });
    }

    private loadCourseTees() {
        this.coursesProxy.getTeeSummary(this.courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.courseTees = response.body;

                    const filter = this.courseTees.map(t => {
                        return {
                            value: t.teeId,
                            label: t.name
                        };
                    });

                    this.teeFilters = [{ value: 0, label: 'ALL TEES' }].concat(filter);
                });
    }

    private loadCourseMap() {
        return this.coursesProxy.getCourseMap(this.courseId)
            .pipe(
                tap(
                    response => {
                        const course = response.body;

                        this.menuItems = course.courseMapAreas.map(
                            ma => {
                                return {
                                    label: ma.name,
                                    title: ma.description,
                                    command: () => {
                                        this.setCurrentMapArea(ma);
                                    }
                                };
                            });

                        this.currentMapArea = course.courseMapAreas.length > 0 ? course.courseMapAreas[0] : undefined;
                        this.course = course;
                    }));
    }

    private loadCourseHistory() {
        return this.golfersProxy.getCourseHistory(this.currentGolfer.golferId, this.courseId)
            .pipe(
                tap(
                    response => {
                        if(response.body.yearsPlayed && response.body.yearsPlayed.length > 0) {
                            this.yearsPlayed = response.body.yearsPlayed;
                            this.mostRecentYearPlayed = this.yearsPlayed[0];
                        }

                        if(response.body && response.body.holeHistory) {
                            this.holeHistory = response.body.holeHistory;
                            this.allTimeHistoryForAllHoles = this.holeHistory[0];
                        }
                    }));
    }

    private setCurrentMapArea(area: ModelsCoreCoursesCourseMapArea) {
        this.currentMapArea = area;
        this.addedShotDispersionTool = false;
        this.showTeeShotDecisionTree = false;
        this.inferGolfClubFromHoleDistance();
        this.loadHoleHistory();
    }

    private inferGolfClubFromHoleDistance() {
        if(this.golfClubs.length === 0) {
            return;
        }

        const yards = this.getHoleDistance();
        this.selectedGolfClub = yards > 0 ? this.getGolfClubNearestDistance(yards) : this.golfClubs[0];
        this.setShotDispersionFromGolfClub(this.selectedGolfClub);
    }

    private getHoleDistance() {
        const teeMarker = this.currentMapArea.courseMapMarkers.find(marker =>
            marker.mapMarker === ModelsCoreCoursesMapMarkers.Tee
            && (marker.tee.teeId === this.filteredTeeId || this.filteredTeeId === 0)
        );
        const greenMarker = this.currentMapArea.courseMapMarkers.find(marker => marker.mapMarker === ModelsCoreCoursesMapMarkers.Green);
        let yards = 0;

        if(teeMarker && greenMarker) {
            const from = new LatLng(teeMarker.latitude, teeMarker.longitude);
            const to = new LatLng(greenMarker.latitude, greenMarker.longitude);
            yards = yardsBetween(from, to);
        }

        return yards;
    }

    private getGolfClubNearestDistance(yardage: number) {
        const clubsWithDistanceDelta = this.golfClubs.map(c => {
            return {
                absoluteDelta: Math.abs(yardage - c.yardage),
                club: c
            };
        });

        clubsWithDistanceDelta.sort((a, b) => a.absoluteDelta > b.absoluteDelta ? 1 : -1);

        return clubsWithDistanceDelta[0].club;
    }

    private loadHoleHistory() {
        if(this.allTimeHistoryForAllHoles && this.currentMapAreaHasHole) {
            const filter = (h: ModelsCoreCoursesHoleHistory) => h.holeNumber === this.currentMapArea.hole.number;
            this.currentHoleAllTimeHistory = this.allTimeHistoryForAllHoles.find(filter);

            for(let i = 0; i < this.yearsPlayed.length; i++) {
                const year = this.yearsPlayed[i];
                const holeHistoryForYear = this.holeHistory[year];
                const holeHistory = holeHistoryForYear ? holeHistoryForYear.find(filter) : undefined;

                if(holeHistory) {
                    this.mostRecentYearPlayed = year;
                    this.currentHoleMostRecentYearHistory = holeHistory;
                    break;
                }
            }
        }
    }

    private loadDrivingDistance() {
        this.golfersProxy.getGolfClubs(this.currentGolfer.golferId)
            .subscribe(response => {
                this.golfClubs = response.body
                    .filter(c => c.golfClubCategory.golfClubCategoryId !== ModelsCoreGolfersGolfClubCategories.Putter);

                if(this.golfClubs.length > 0) {
                    this.selectedGolfClub = this.golfClubs[0];
                    this.setShotDispersionFromGolfClub(this.selectedGolfClub);
                }
            });
    }

    private setShotDispersionFromGolfClub(golfClub: ModelsCoreGolfersGolfClub) {
        if(golfClub) {
            this.golfClubShotDispersion = {
                yardage: golfClub.yardage,
                lateralDispersion: golfClub.lateralDispersion || 0,
                overrideDistanceCap: true
            };
        }
        else {
            this.golfClubShotDispersion = undefined;
        }
    }
}
