import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { GolferLookupService } from '../../../shared/golfers';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreSharedChartingChartData,
    ModelsWebApiGolfersGetHistoricalHandicapsArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-historical-handicaps',
    templateUrl: './historical-handicaps.component.html',
    standalone: false
})
export class HistoricalHandicapsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private currentRoute: ActivatedRoute) {
        super();
    }

    golferId: number;
    yearsPlayed: SelectItem[];
    chartData: ModelsCoreSharedChartingChartData;
    args: ModelsWebApiGolfersGetHistoricalHandicapsArgs;

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const golferIdParam = params['golferId'];
                    this.golferId = parseInt(golferIdParam, 10) || this.golferLookups.defaultFriendId;

                    this.golfersProxy.getYearsPlayed(this.golferId)
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            response => {
                                this.yearsPlayed = response.body.map(
                                    year => {
                                        return {
                                            value: year,
                                            label: year.toString()
                                        } as SelectItem;
                                    });

                                const firstYear = this.yearsPlayed.length > 0 ? this.yearsPlayed[0].value : 0;
                                this.yearsPlayed.unshift({ value: 0, label: 'ALL' });
                                this.args = { year: firstYear };

                                this.loadHistoricalHandicaps();
                            });
                });
    }

    onYearChanged() {
        this.loadHistoricalHandicaps();
    }

    private loadHistoricalHandicaps() {
        this.taskStarted();
        this.golfersProxy.getHistoricalHandicaps(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.chartData = response.body.historicalHandicaps;
                });
    }
}
