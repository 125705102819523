/// <reference types="google.maps" />
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DeleteMenu } from './delete-menu';
import { GreenMapMarker } from './green-map-marker';
import { LocationModifierMapMarker } from './location-modifier-map-marker';
import { MapMarkerWrapper } from './map-marker-wrapper';
import { MeasuringTool } from './measuring-tool';
import { ShotDispersionTool, GolfClubShotDispersion, ShotDispersionToolUpdates as ShotDispersionToolUpdate } from './shot-dispersion-tool';
import { TeeMapMarker, TeeModel } from './tee-map-marker';
import { TeeShotTargetMapMarker } from './tee-shot-target-map-marker';
import { TeeToGreenLine, TeeToGreenLineUpdates as TeeToGreenLineUpdate } from './tee-to-green-line';
import { YardageMapMarker } from './yardage-map-marker';
import { BaseComponentDirective } from '../../ui/base-component.directive';
import { disposeMapComponents, yardsBetween, yardsToMeters } from './map-utilities';
import { locationMarkerIcon, locationRedMarkerIcon, defaultTeeMarkerIcon } from './map-marker-icons';

import LatLng = google.maps.LatLng;
import LatLngBounds = google.maps.LatLngBounds;
import MapOptions = google.maps.MapOptions;
import MapTypeId = google.maps.MapTypeId;
import Point = google.maps.Point;
import computeHeading = google.maps.geometry.spherical.computeHeading;
import computeOffset = google.maps.geometry.spherical.computeOffset;

import {
    ModelsCoreCoursesMapMarkers,
    ModelsCoreCoursesCourse,
    ModelsCoreCoursesCourseMapArea,
    ModelsCoreRoundsGolfRoundGolferHoleStroke,
    ModelsCoreRoundsGolfRoundHoleLocation,
    ModelsCoreRoundsLieTypes,
    ModelsCoursesWhichTeeTeeSummary,
    ModelsWebApiCoursesSaveCourseMapAreaModel,
    ModelsWebApiCoursesSaveCourseMapAreaCourseMapMarkerModel
} from '../../swagger-codegen/models';

@Component({
    selector: 'my-course-map',
    template: `<google-map
        *ngIf="mapOptions"
        [options]="mapOptions"
        [style]="{'width':'100%','height':'500px'}"
        (mapInitialized)="onMapInitialized($event)"></google-map>`,
    standalone: false
})
export class CourseMapComponent extends BaseComponentDirective implements OnInit, OnChanges {
    mapOptions: MapOptions;

    @Input() course: ModelsCoreCoursesCourse;
    @Input() courseMapArea: ModelsCoreCoursesCourseMapArea;
    @Input() golfClubShotDispersion: GolfClubShotDispersion;
    @Input() filteredTeeId: number;
    @Input() showTeeToGreenLine: boolean;
    @Input() holeStrokes: ModelsCoreRoundsGolfRoundGolferHoleStroke[];
    @Input() holeLocation: ModelsCoreRoundsGolfRoundHoleLocation;
    @Output() shotDispersionToolDeleted = new EventEmitter<any>();
    private teeMarkers: TeeMapMarker[] = [];
    private currentTeeMarker: TeeMapMarker;
    private teeShotTargetMarker: TeeShotTargetMapMarker;
    private yardageMarkers: YardageMapMarker[] = [];
    private greenMarker: GreenMapMarker;
    private locationModifierMarkers: LocationModifierMapMarker[] = [];
    private teeToGreenLine: TeeToGreenLine;
    private measuringTool: MeasuringTool;
    private shotDispersionTool: ShotDispersionTool;
    private deleteMenu: DeleteMenu;
    private map: google.maps.Map;
    private overlay: google.maps.OverlayView;

    get hasCourseMapArea() {
        return this.courseMapArea !== undefined;
    }

    get isMapLoaded() {
        return this.map ? true : false;
    }

    get courseMapAreaHasHole() {
        return this.courseMapArea?.hole?.holeId > 0;
    }

    get hasHoleStrokes() {
        return this.holeStrokes !== undefined;
    }

    private get maxYardageMarkers() {
        return this.courseMapAreaHasHole && !this.hasHoleStrokes ? 3 : 15;
    }

    private maxLocationModifierMarkers = 10;

    ngOnInit() {
        this.loadCourseMap();
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if(this.course && changes['course']) {
            this.loadCourseMap();
        }

        if(this.courseMapArea && changes['courseMapArea']) {
            this.loadCourseMapArea(this.courseMapArea);
        }

        if(this.golfClubShotDispersion && changes['golfClubShotDispersion']) {
            this.updateShotDispersionTool({ golfClubShotDispersion: this.golfClubShotDispersion, reset: true });
        }

        if(this.filteredTeeId && changes['filteredTeeId']) {
            this.filterTeeMarkers();
        }

        if(changes['showTeeToGreenLine']) {
            if(this.teeToGreenLine) {
                this.teeToGreenLine.setVisible(this.showTeeToGreenLine);
            }
        }

        if((this.holeStrokes && changes['holeStrokes']) || changes['holeLocation']) {
            this.loadCourseMap(changes['holeLocation'] === undefined);
        }
    }
    
    loadCourseMap(repositionMap = true) {
        if(this.course) {
            if(this.courseMapArea && this.course.courseMapAreas.length > 0) {
                this.courseMapArea = this.course.courseMapAreas
                    .find(ma => ma.courseMapAreaId === this.courseMapArea.courseMapAreaId);
            }
            else {
                this.courseMapArea = undefined;
            }

            if(!this.courseMapArea) {
                this.courseMapArea = this.course.courseMapAreas.length > 0 ? this.course.courseMapAreas[0] : undefined;
            }

            const mapOptions: MapOptions = {
                center: {
                    lat: 0,
                    lng: 0
                },
                zoom: 12,
                mapTypeId: MapTypeId.SATELLITE,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false
            };

            if(this.courseMapArea && this.courseMapArea.latitude && this.courseMapArea.longitude) {
                mapOptions.center.lat = this.courseMapArea.latitude;
                mapOptions.center.lng = this.courseMapArea.longitude;
                mapOptions.zoom = this.courseMapArea.zoom;
                mapOptions.heading = this.courseMapArea.heading;
                mapOptions.tilt = this.courseMapArea.tilt;
            }
            else {
                mapOptions.center.lat = this.course.latitude;
                mapOptions.center.lng = this.course.longitude;
                mapOptions.zoom = 16;
            }

            this.mapOptions = mapOptions;
            this.loadMapMarkers(this.courseMapArea, repositionMap);
        }
    }

    loadHoleById(holeId: number) {
        const hole = this.getHoleById(holeId);
        this.loadCourseMapArea(hole);
    }

    getHoleById(holeId: number) {
        return this.course && this.course.courseMapAreas
            ? this.course.courseMapAreas.find(ma => ma.hole && ma.hole.holeId === holeId) : undefined;
    }

    loadCourseMapArea(area: ModelsCoreCoursesCourseMapArea) {
        this.courseMapArea = area;

        if(area) {
            this.initializeMapForArea(area);
            this.loadMapMarkers(area);
        }
    }

    onMapInitialized(googleMap: google.maps.Map) {
        this.map = googleMap;
        this.deleteMenu = new DeleteMenu(this.map);

        this.deleteMenu.addClickListener(target => {
            this.deleteMapComponent(target);
            this.deleteMenu.close();
        });
        
        if(this.courseMapArea) {
            this.loadCourseMapArea(this.courseMapArea);
        }

        this.overlay = new google.maps.OverlayView();
        this.overlay.setMap(this.map);
    }

    addTeeMarker(tee: ModelsCoursesWhichTeeTeeSummary, point?: Point) {
        if(this.teeMarkers.find(m => m.tee.teeId === tee.teeId)) {
            return;
        }

        const position = this.getNewMarkerPosition(point);
        const marker = this.createTeeMarker(
            position,
            {
                teeId: tee.teeId,
                name: tee.name,
                cssClassName: tee.cssClassName
            }
        );

        this.teeMarkerAdded(marker);
    }

    addGreenMarker(point?: Point) {
        if(this.greenMarker) {
            return undefined;
        }

        const position = this.getNewMarkerPosition(point);
        this.createGreenMarker(position);

        this.greenMarkerAdded();

        return position;
    }
    
    addYardageMarker(point?: Point, ordinal?: number) {
        if(this.yardageMarkers.length < this.maxYardageMarkers) {
            const position = this.getNewMarkerPosition(point);
            this.createYardageMarker(position, undefined, undefined, ordinal);
            
            this.yardageMarkerAdded();

            return position;
        }

        return undefined;
    }

    addLocationModifierMarker(modifier: number, point?: Point) {
        if(this.locationModifierMarkers.length < this.maxLocationModifierMarkers) {
            const position = this.getNewMarkerPosition(point);
            this.createLocationModifierMarker(position, modifier);

            this.locationModifierMarkerAdded();

            return position;
        }

        return undefined;
    }

    addTeeShotTargetMarker(point?: Point) {
        if(this.teeShotTargetMarker) {
            return undefined;
        }

        let position = this.getNewMarkerPosition(point);

        if(this.currentTeeMarker && (this.yardageMarkers.length > 0 || this.greenMarker)) {
            const target = this.yardageMarkers.length > 0 ? this.yardageMarkers[0] : this.greenMarker;
            const heading = computeHeading(this.currentTeeMarker.getPosition(), target.getPosition());
            const yards = yardsBetween(this.currentTeeMarker.getPosition(), target.getPosition());
            position = computeOffset(this.currentTeeMarker.getPosition(), yardsToMeters(yards + 50), heading);
        }

        this.createTeeShotTargetMarker(position);

        this.teeShotTargetMarkerAdded();

        return position;
    }
    
    addMeasuringTool(point?: Point) {
        if(!this.measuringTool) {
            const position = this.getNewMarkerPosition(point);
            this.measuringTool = new MeasuringTool(this.map, position);
            this.measuringTool.addDeleteListener(this.deleteMenu);

            return position;
        }

        return undefined;
    }

    addShotDispersionTool(point?: Point) {
        if(!this.shotDispersionTool) {
            this.addTeeShotTargetMarker();
            const position = this.getNewMarkerPosition(point);
            this.shotDispersionTool = new ShotDispersionTool(this.map, position, this.courseMapArea.hole.par, this.teeShotTargetMarker);
            this.shotDispersionTool.addDeleteListener(this.deleteMenu);
            
            this.updateShotDispersionTool({
                teeMarker: this.currentTeeMarker,
                teeShotTargetMarker: this.teeShotTargetMarker,
                yardageMarkers: this.yardageMarkers,
                greenMarker: this.greenMarker,
                golfClubShotDispersion: this.golfClubShotDispersion,
                reset: true
            });

            return position;
        }

        return undefined;
    }

    getUpdateModel() {
        const center = this.map.getCenter();
        const mapMarkerUpdateModels = this.getMapMarkerUpdateModels();

        const model: ModelsWebApiCoursesSaveCourseMapAreaModel = {
            latitude: center.lat(),
            longitude: center.lng(),
            zoom: this.map.getZoom(),
            heading: this.map.getHeading(),
            tilt: this.map.getTilt(),
            holeId: this.courseMapAreaHasHole ? this.courseMapArea.hole.holeId : undefined,
            courseMapMarkers: mapMarkerUpdateModels
        };

        return model;
    }

    private getNewMarkerPosition(point?: Point) {
        return point
            ? this.point2LatLng(point)
            : this.map.getCenter();
    }

    private point2LatLng(point: Point) {
        const div: any = this.map.getDiv();
        const offsetPoint = new Point(
            point.x - div.offsetLeft,
            point.y - div.offsetTop
        );

        return this.overlay.getProjection().fromContainerPixelToLatLng(offsetPoint);
    }

    private getMapMarkerUpdateModels() {
        const updateModels: ModelsWebApiCoursesSaveCourseMapAreaCourseMapMarkerModel[] = [];
        const markersToSave: MapMarkerWrapper[] = [];

        markersToSave.push(...this.teeMarkers);
        markersToSave.push(...this.yardageMarkers);
        markersToSave.push(...this.locationModifierMarkers);

        if(this.greenMarker) {
            markersToSave.push(this.greenMarker);
        }

        if(this.teeShotTargetMarker) {
            markersToSave.push(this.teeShotTargetMarker);
        }

        markersToSave.forEach(marker => {
            const model = marker.createUpdateModel();
            updateModels.push(model);
        });
        
        return updateModels;
    }
    
    private loadMapMarkers(area: ModelsCoreCoursesCourseMapArea, repositionMap = true) {
        if(!this.isMapLoaded) {
            return;
        }
        
        this.dispose();
        
        if(this.holeStrokes) {
            this.loadHoleStrokeMapMarkers(repositionMap);
        }
        else if(area) {
            this.loadCourseMapMarkers(area, repositionMap);
        }
    }

    private loadHoleStrokeMapMarkers(repositionMap: boolean) {
        this.holeStrokes
            .filter(holeStroke => {
                return holeStroke.latitude && holeStroke.longitude;
            })
            .forEach((holeStroke, i) => {
                let penalties = 0;

                const previousHoleStroke = i > 0 ? this.holeStrokes[i - 1] : undefined;

                switch(previousHoleStroke?.toLieType?.lieTypeId) {
                    case ModelsCoreRoundsLieTypes.Penalty1Stroke:
                        penalties = 1;
                        break;
                    case ModelsCoreRoundsLieTypes.Penalty2Stroke:
                        penalties = 2;
                        break;
                }
                
                const position = new LatLng(holeStroke.latitude, holeStroke.longitude);
                const accuracy = holeStroke.locationAccuracy > 0 ? `(+/- ${holeStroke.locationAccuracy} Yards)` : '';
                const labelText = previousHoleStroke ? previousHoleStroke.ordinal.toString() : undefined;
                const title = `${(previousHoleStroke?.description || '')} ${accuracy}`.trim();
                const icon = holeStroke.ordinal === 1
                    ? defaultTeeMarkerIcon
                    : (penalties > 0 ? locationRedMarkerIcon : locationMarkerIcon);
                const marker = this.createYardageMarker(position, title, icon);

                if(holeStroke.toLieType.lieTypeId === ModelsCoreRoundsLieTypes.Hole) {
                    marker.nativeMarker.set('finishedInHole', true);
                }

                if(labelText) {
                    marker.nativeMarker.setLabel({
                        text: labelText,
                        color: '#FFFFFF'
                    });
                }
                else {
                    marker.nativeMarker.setLabel(undefined);
                }
            });

        if(this.holeLocation && this.holeLocation.latitude && this.holeLocation.longitude) {
            const position = new LatLng(this.holeLocation.latitude, this.holeLocation.longitude);
            this.createGreenMarker(position);
        }

        if(!this.teeToGreenLine) {
            this.teeToGreenLine = new TeeToGreenLine(this.map, true);
            this.teeToGreenLine.requireTeeMarker = false;
            this.teeToGreenLine.requireGreenMarker = false;
            this.teeToGreenLine.showTotalYardageLabel = false;
        }

        this.updateTeeToGreenLine({ yardageMarkers: this.yardageMarkers, greenMarker: this.greenMarker });

        if(repositionMap) {
            this.positionMapAroundHoleStrokes();
        }
    }

    private positionMapAroundHoleStrokes() {
        const holeStrokesWithLocation = this.holeStrokes.filter(hs => hs.latitude && hs.longitude);

        if(holeStrokesWithLocation.length > 0) {
            const bounds = new LatLngBounds();
            const first = holeStrokesWithLocation[0];
            let last = first;

            holeStrokesWithLocation.forEach(holeStroke => {
                bounds.extend(new LatLng(holeStroke.latitude, holeStroke.longitude));
                last = holeStroke;
            });

            if(first !== last) {
                const heading = computeHeading(
                    new LatLng(first.latitude, first.longitude),
                    new LatLng(last.latitude, last.longitude)
                );

                this.map.setHeading(heading);
            }

            this.positionMapAroundBounds(bounds);
        }
    }
    
    private loadCourseMapMarkers(area: ModelsCoreCoursesCourseMapArea, repositionMap = true) {
        if(area?.courseMapMarkers?.length > 0) {
            const bounds = new LatLngBounds();

            area.courseMapMarkers.forEach(
                (courseMapMarker) => {
                    const position = new LatLng(courseMapMarker.latitude, courseMapMarker.longitude);
                    let supportedMarker = true;

                    switch(courseMapMarker.mapMarker) {
                        case ModelsCoreCoursesMapMarkers.Tee:
                            if(courseMapMarker.tee) {
                                this.createTeeMarker(
                                    position,
                                    {
                                        teeId: courseMapMarker.tee.teeId,
                                        name: courseMapMarker.tee.name,
                                        cssClassName: courseMapMarker.tee.cssClassName
                                    }
                                );
                            }
                            break;
                        case ModelsCoreCoursesMapMarkers.Yardage:
                            this.createYardageMarker(position);
                            break;
                        case ModelsCoreCoursesMapMarkers.Green:
                            this.createGreenMarker(position);
                            break;
                        case ModelsCoreCoursesMapMarkers.TeeShotTarget:
                            this.createTeeShotTargetMarker(position);
                            break;
                        case ModelsCoreCoursesMapMarkers.LocationModifier:
                            this.createLocationModifierMarker(position, courseMapMarker.modifier);
                            break;
                        default:
                            supportedMarker = false;
                            break;
                    }

                    if(supportedMarker) {
                        bounds.extend(position);
                    }
                });

            this.filterTeeMarkers();
            this.updateTeeToGreenLine();

            if(repositionMap && area.courseMapMarkers.length > 1) {
                this.positionMapAroundBounds(bounds);
            }
        }
    }

    private positionMapAroundBounds(bounds: LatLngBounds) {
        this.map.setCenter(bounds.getCenter());
        this.map.fitBounds(bounds);
    }

    private dispose() {
        disposeMapComponents(
            this.teeMarkers,
            this.yardageMarkers,
            this.locationModifierMarkers,
            this.greenMarker,
            this.teeShotTargetMarker,
            this.measuringTool,
            this.shotDispersionTool,
            this.teeToGreenLine
        );
    
        this.currentTeeMarker = undefined;
        this.greenMarker = undefined;
        this.teeShotTargetMarker = undefined;
        this.measuringTool = undefined;
        this.shotDispersionTool = undefined;
        this.teeToGreenLine = undefined;
    }

    private deleteMapComponent(component: any) {
        const type: ModelsCoreCoursesMapMarkers = component.type;

        if(type) {
            switch(type) {
                case ModelsCoreCoursesMapMarkers.Tee:
                    this.removeFromArray(component, this.teeMarkers);
                    this.teeMarkerDeleted(component);
                    break;
                case ModelsCoreCoursesMapMarkers.Yardage:
                    this.removeFromArray(component, this.yardageMarkers);
                    this.yardageMarkerDeleted();
                    break;
                case ModelsCoreCoursesMapMarkers.Green:
                    this.greenMarker = undefined;
                    this.greenMarkerDeleted();
                    break;
                case ModelsCoreCoursesMapMarkers.TeeShotTarget:
                    this.teeShotTargetMarker = undefined;
                    this.teeShotTargetMarkerDeleted();
                    break;
                case ModelsCoreCoursesMapMarkers.LocationModifier:
                    this.removeFromArray(component, this.locationModifierMarkers);
                    this.locationModifierMarkerDeleted();
                    break;
            }
        }
        else if(this.measuringTool === component) {
            this.measuringTool = undefined;
        }
        else if(this.shotDispersionTool === component) {
            this.shotDispersionTool = undefined;
            this.shotDispersionToolDeleted.emit();
        }

        disposeMapComponents(component);
    }
    
    private initializeMapForArea(area: ModelsCoreCoursesCourseMapArea) {
        if(this.map && area.latitude && area.longitude) {
            this.map.setCenter({ lat: area.latitude, lng: area.longitude });
            this.map.setZoom(area.zoom);
            this.map.setHeading(area.heading);
            this.map.setTilt(area.tilt);
        }
    }

    private filterTeeMarkers() {
        const isFiltered = this.filteredTeeId > 0;

        this.teeMarkers.forEach(marker => marker.nativeMarker.setVisible(!isFiltered || marker.tee.teeId === this.filteredTeeId));

        if(!this.currentTeeMarker || this.currentTeeMarker.tee.teeId !== this.filteredTeeId) {
            this.selectFirstVisibleTeeMarker();
        }
    }

    private selectFirstVisibleTeeMarker() {
        const i = this.teeMarkers.findIndex(t => t.nativeMarker.getVisible());

        if(i !== -1) {
            this.teeMarkerClicked(this.teeMarkers[i]);
        }
    }

    private createTeeMarker(position: LatLng, tee: TeeModel) {
        const marker = new TeeMapMarker(this.map, position, tee);
        marker.nativeMarker.addListener('drag', () => this.teeMarkerDragged(marker));
        marker.nativeMarker.addListener('click', () => this.teeMarkerClicked(marker));
        marker.addDeleteListener(this.deleteMenu);

        this.teeMarkers.push(marker);

        return marker;
    }

    private teeMarkerAdded(marker: TeeMapMarker) {
        if(!this.currentTeeMarker) {
            this.currentTeeMarker = marker;
            this.updateShotDispersionTool({ teeMarker: marker });
            this.updateTeeToGreenLine({ teeMarker: marker });
        }
    }

    private teeMarkerDeleted(marker: TeeMapMarker) {
        if(this.currentTeeMarker === marker) {
            this.selectFirstVisibleTeeMarker();
        }
    }
    
    private teeMarkerClicked(marker: TeeMapMarker) {
        if(marker !== this.currentTeeMarker) {
            this.currentTeeMarker = marker;
            this.updateShotDispersionTool({ teeMarker: marker, reset: true });
            this.updateTeeToGreenLine({ teeMarker: marker });
        }
    }

    private teeMarkerDragged(marker: TeeMapMarker) {
        if(this.currentTeeMarker === marker) {
            this.updateTeeToGreenLine({ teeMarker: marker });
            this.updateShotDispersionTool({ teeMarker: marker });
        }
    }

    private createGreenMarker(position: LatLng) {
        const marker = new GreenMapMarker(this.map, position);
        marker.nativeMarker.addListener('drag', () => this.greenMarkerDragged());
        marker.addDeleteListener(this.deleteMenu);

        this.greenMarker = marker;

        return marker;
    }

    private greenMarkerAdded() {
        this.updateShotDispersionTool({ greenMarker: this.greenMarker });
        this.updateTeeToGreenLine({ greenMarker: this.greenMarker });
    }

    private greenMarkerDeleted() {
        this.updateTeeToGreenLine({ greenMarker: undefined });
    }

    private greenMarkerDragged() {
        this.updateTeeToGreenLine();
        this.updateShotDispersionTool();
    }

    private createYardageMarker(position: LatLng, title?: string, iconUrl?: string, ordinal?: number) {
        if(ordinal === undefined) {
            ordinal = this.yardageMarkers.length + 1;
        }
        
        const marker = new YardageMapMarker(this.map, position, ordinal, title, iconUrl);
        marker.nativeMarker.addListener('drag', () => this.yardageMarkerDragged());
        marker.addDeleteListener(this.deleteMenu);

        if(!this.courseMapAreaHasHole) {
            marker.nativeMarker.setIcon(GreenMapMarker.createIcon());
            marker.nativeMarker.setLabel('');
        }

        this.yardageMarkers.push(marker);

        return marker;
    }

    private yardageMarkerAdded() {
        this.updateTeeToGreenLine({ yardageMarkers: this.yardageMarkers });
    }

    private yardageMarkerDeleted() {
        this.yardageMarkers.forEach((marker, i) => marker.ordinal = i + 1);
        this.updateTeeToGreenLine({ yardageMarkers: this.yardageMarkers });
    }

    private yardageMarkerDragged() {
        this.updateTeeToGreenLine();
    }

    private createTeeShotTargetMarker(position: LatLng) {
        const marker = new TeeShotTargetMapMarker(this.map, position);
        marker.nativeMarker.addListener('drag', () => this.teeShotTargetMarkerDragged());
        marker.addDeleteListener(this.deleteMenu);

        this.teeShotTargetMarker = marker;

        return marker;
    }

    private teeShotTargetMarkerAdded() {
        this.updateShotDispersionTool({ teeShotTargetMarker: this.teeShotTargetMarker });
    }

    private teeShotTargetMarkerDeleted() {

    }

    private teeShotTargetMarkerDragged() {
        this.updateShotDispersionTool({ teeShotTargetMarker: this.teeShotTargetMarker });
    }

    private createLocationModifierMarker(position: LatLng, modifier: number) {
        const marker = new LocationModifierMapMarker(this.map, position, modifier);
        marker.addDeleteListener(this.deleteMenu);

        this.locationModifierMarkers.push(marker);

        return marker;
    }

    private locationModifierMarkerAdded() {

    }

    private locationModifierMarkerDeleted() {

    }

    private updateShotDispersionTool(updatedMapMarkers?: ShotDispersionToolUpdate) {
        if(this.shotDispersionTool) {
            this.shotDispersionTool.update(updatedMapMarkers);
        }
    }

    private updateTeeToGreenLine(updatedMapMarkers?: TeeToGreenLineUpdate) {
        if(this.teeToGreenLine) {
            this.teeToGreenLine.update(updatedMapMarkers);
        }
        else if(this.currentTeeMarker && this.greenMarker) {
            this.teeToGreenLine = new TeeToGreenLine(this.map);
            this.teeToGreenLine.update({
                teeMarker: this.currentTeeMarker,
                yardageMarkers: this.yardageMarkers,
                greenMarker: this.greenMarker
            });

            if(!this.showTeeToGreenLine) {
                this.teeToGreenLine.setVisible(this.showTeeToGreenLine);
            }
        }
    }
    
    private removeFromArray(item: any, array: any[]) {
        const i = array.findIndex(element => element === item);

        if(i !== -1) {
            array.splice(i, 1);
        }
    }
}
