import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { PracticeControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { GolferLookupService, IGolferSelectItem } from '../../shared/golfers/golfer-lookup.service';

import {
    ModelsCoreSharedChartingChartDataBase,
    ModelsPracticeGetPracticeChartPracticeCharts,
    ModelsWebApiPracticeGetPracticeChartArgs
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-charts',
    templateUrl: './practice-charts.component.html',
    standalone: false
})
export class PracticeChartsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private practiceControllerProxy: PracticeControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    charts: SelectItem[] = [
        {
            value: ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerCategoryByMonth,
            label: 'Practice Time Per Category by Month'
        },
        {
            value: ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerTypeByMonth,
            label: 'Practice Time Per Type by Month'
        },
        {
            value: ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerTypeByCategory,
            label: 'Practice Time Per Type by Category'
        },
        {
            value: ModelsPracticeGetPracticeChartPracticeCharts.ImprovementPlan,
            label: 'Improvement Plan Progress'
        }
    ];
    yearsPracticed: SelectItem[];
    golfers: IGolferSelectItem[];
    chartData: ModelsCoreSharedChartingChartDataBase;
    args = {
        year: 0,
        chart: ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerCategoryByMonth
    } as ModelsWebApiPracticeGetPracticeChartArgs;
    private golferId: number;

    get showPracticeTimeByMonthChart() {
        return this.isChart(
            ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerTypeByMonth,
            ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerCategoryByMonth);
    }

    get showPracticeTimeByCategoryChart() {
        return this.isChart(ModelsPracticeGetPracticeChartPracticeCharts.PracticeTimePerTypeByCategory);
    }

    get showImprovementPlanChart() {
        return this.isChart(ModelsPracticeGetPracticeChartPracticeCharts.ImprovementPlan);
    }

    get shouldShowGolferSelector() {
        return this.currentGolfer.isAdmin;
    }

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;
        this.args.golferId = this.golferId;

        this.loadFriends();
        this.loadYearsPracticed();
    }

    onYearChanged() {
        this.loadPracticeCharts();
    }

    onChartChanged() {
        this.chartData = undefined;
        this.loadPracticeCharts();
    }

    onGolferChanged() {
        this.loadPracticeCharts();
    }

    private loadYearsPracticed() {
        this.golferLookups.getYearsPracticedSelectItems(false)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                years => {
                    this.yearsPracticed = years;
                    this.args.year = this.golferLookups.defaultYearPracticed;
                    this.loadPracticeCharts();
                });
    }

    private loadFriends() {
        this.golferLookups.getFriendSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                friends => {
                    this.golfers = friends;
                    this.golferId = this.currentGolfer.golferId;
                });
    }

    private loadPracticeCharts() {
        this.practiceControllerProxy.getPracticeChart(this.args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.chartData = response.body.chart;
                });
    }

    private isChart(...charts: ModelsPracticeGetPracticeChartPracticeCharts[]) {
        for(let i = 0; i < charts.length; i++) {
            if(this.args.chart === charts[i]) {
                return true;
            }
        }

        return false;
    }
}
