import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CoursesControllerProxy } from '../../shared/server-proxies';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreCoursesCourse
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-course-detail-tab-view',
    templateUrl: './course-detail-tab-view.component.html',
    standalone: false
})
export class CourseDetailTabViewComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    course: ModelsCoreCoursesCourse;
    activeTabIndex: number;

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.activeTabIndex = parseInt(params['tab'], 10) || 0;

                    const courseIdParam = params['courseId'];
                    const courseId = parseInt(courseIdParam, 10);

                    if(courseId) {
                        this.loadCourse(courseId);
                    }
                    else {
                        this.messages.showError(`Invalid course id: ${courseIdParam}`);
                    }
                });
    }

    onActiveTabChanged(e: any) {
        const params = {
            tab: e.index
        };

        this.router.navigate(['courses', this.course.courseId, params]);
    }

    private loadCourse(courseId: number) {
        return this.coursesProxy.getCourse(courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.course = response.body;
                },
                error => {
                    if(error.status === 404) {
                        this.messages.showError(`Course ${courseId} does not exist.`);
                    }
                    else {
                        this.messages.showError(`An error occurred. (${error.status})`);
                    }
                });
    }
}
