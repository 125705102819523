import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { tap, finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService, GolferLookupService } from '../../shared/golfers';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsStatisticsGetHeadToHeadStatisticsGolferHeadToHeadStatistics,
    ModelsWebApiGolfersGetHeadToHeadStatisticsArgs
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum HeadToHeadStatsViewOption {
    byRound = 1,
    byHole = 2
}

@Component({
    selector: 'my-head-to-head-statistics',
    templateUrl: './head-to-head-statistics.component.html',
    standalone: false
})
export class HeadToHeadStatisticsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    headToHeadStats: ModelsStatisticsGetHeadToHeadStatisticsGolferHeadToHeadStatistics[] = [];
    args: ModelsWebApiGolfersGetHeadToHeadStatisticsArgs = {};
    yearsPlayed: SelectItem[];
    quarters: SelectItem[] = [
        { label: 'ALL', value: 0 },
        { label: 'Q1', value: 1 },
        { label: 'Q2', value: 2 },
        { label: 'Q3', value: 3 },
        { label: 'Q4', value: 4 }
    ];
    months: SelectItem[] = [
        { label: 'ALL', value: 0 },
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];
    viewOptions: SelectItem[] = [
        { label: 'By Round', value: HeadToHeadStatsViewOption.byRound },
        { label: 'By Hole', value: HeadToHeadStatsViewOption.byHole }
    ];
    selectedView = HeadToHeadStatsViewOption.byRound;

    get shouldShowStatsByRound() {
        return this.selectedView === HeadToHeadStatsViewOption.byRound;
    }

    get shouldShowStatsByHole() {
        return this.selectedView === HeadToHeadStatsViewOption.byHole;
    }

    get shouldShowMonths() {
        return this.args.quarter === 0;
    }

    ngOnInit(): void {
        this.taskStarted();
        this.initializeSelectLists()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.loadHeadToHeadStatistics();
                });
    }

    onYearChanged() {
        this.loadHeadToHeadStatistics();
    }

    onQuarterChanged() {
        this.args.month = 0;
        this.loadHeadToHeadStatistics();
    }

    onMonthChanged() {
        this.loadHeadToHeadStatistics();
    }

    private initializeSelectLists() {
        return this.golferLookups.getYearsPlayedSelectItems()
            .pipe(
                tap(
                    years => {
                        this.yearsPlayed = years;
                        this.args.year = this.golferLookups.defaultYearPlayed;
                        this.args.quarter = 0;
                        this.args.month = 0;
                    }
                )
            );
    }

    private loadHeadToHeadStatistics() {
        this.taskStarted();

        this.golfersProxy.getHeadToHeadStatistics(this.currentGolfer.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.headToHeadStats = response.body;
                });
    }
}
