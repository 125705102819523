import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-score-by-hole-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class ScoreByHoleLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');

        const ticks = this.options.scales.y.ticks;

        ticks.stepSize = 1;
        ticks.callback = this.getTick.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const score = data[tooltipItem.dataIndex];

        if(score === 0) {
            return 'Par';
        }
        else if(score > 0) {
            return `+${score}`;
        }
        else {
            return score.toString();
        }
    }

    private getTick(value: any) {
        if(value === 0) {
            return 'EVEN';
        }
        else if(value > 0) {
            return `+${value}`;
        }
        else {
            return value.toString();
        }
    }
}
