import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationControllerProxy } from '../../shared/server-proxies';
import { SessionService } from '../../shared/auth/session.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

@Component({
    selector: 'my-logout',
    template: '',
    standalone: false
})
export class LogoutComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private session: SessionService,
        private authenticationProxy: AuthenticationControllerProxy,
        private router: Router) {
        super();
    }

    ngOnInit() {
        this.authenticationProxy.logout()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.session.disposeSession();
                    this.router.navigateByUrl('/login');
                });
    }
}
