/* eslint-disable */
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MegaMenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { BaseComponentDirective } from '../shared/ui/base-component.directive';
import { SessionService } from '../shared/auth/session.service';
import { ConfigurationService } from '../shared/core/configuration.service';
import { MessagePubSubService } from '../shared/core/message-pub-sub.service';
import { GolfersControllerProxy } from '../shared/server-proxies';

import {
    ModelsCoreGolfersGolfer,
    ModelsGolfersGetFriendRequestsFriendRequest
} from '../shared/swagger-codegen/models';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: [
        './app.topbar.component.scss'
    ],
    standalone: false
})
export class AppTopBarComponent extends BaseComponentDirective implements OnInit {

    constructor(public layoutService: LayoutService, public el: ElementRef,
        private golfersProxy: GolfersControllerProxy,
        private configurationService: ConfigurationService,
        private messagePubSub: MessagePubSubService,
        private session: SessionService) {
        super();
    }

    @ViewChild('menuButton') menuButton!: ElementRef;
    @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;
    @ViewChild('searchInput') searchInput!: ElementRef;
    
    get currentGolfer(): ModelsCoreGolfersGolfer {
        return this.session.getGolfer();
    }

    get hasFriendRequests() {
        return this.friendRequests.length > 0;
    }
    
    get friendRequestMessage() {
        const count = this.friendRequests.length
        return `You Have ${count} Friend ${(count > 0 ? 'Requests' : 'Request')}`;
    }

    friendRequests: ModelsGolfersGetFriendRequestsFriendRequest[] = [];
    environmentName: string;
    isNonProduction: boolean;
    newVersion: string;
    activeItem!: number;

    model: MegaMenuItem[] = [
        {
            label: 'UI KIT',
            items: [
                [
                    {
                        label: 'UI KIT 1',
                        items: [
                            { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
                            { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
                            { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
                            { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'] },
                            { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] }
                        ]
                    }
                ],
                [
                    {
                        label: 'UI KIT 2',
                        items: [
                            { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
                            { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
                            { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
                            { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
                            { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] }
                        ]
                    }
                ],
                [
                    {
                        label: 'UI KIT 3',
                        items: [
                            { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
                            { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
                            { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
                            { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
                            { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] }
                        ]
                    }
                ]
            ]
        },
        {
            label: 'UTILITIES',
            items: [
                [
                    {
                        label: 'UTILITIES 1',
                        items: [
                            { label: 'Icons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons'] },
                            { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank' }
                        ]
                    }
                ],

            ]
        }
    ];

    get mobileTopbarActive(): boolean {
        return this.layoutService.state.topbarMenuActive;
    }

    ngOnInit() {
        if(this.currentGolfer) {
            this.loadFriendRequests(this.currentGolfer);
        }

        this.environmentName = this.configurationService.environmentName;
        this.isNonProduction = this.environmentName && this.environmentName.toLowerCase() !== 'production';

        this.messagePubSub.observeMessages()
            .pipe(
                filter(message => message.id === 'golfers:user-logged-in'),
                this.takeUntilUnsubscribed())
            .subscribe(message => this.loadFriendRequests(message.content as ModelsCoreGolfersGolfer));

        this.configurationService.versionUpdates.subscribe(update => {
            this.newVersion = update.newVersion;
        });
    }

    acceptFriendRequest(friendRequest: ModelsGolfersGetFriendRequestsFriendRequest, index: number) {
        this.golfersProxy.acceptFriendRequest(friendRequest.sender.golferId, this.currentGolfer.golferId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.friendRequests.splice(index, 1);
            });
    }

    rejectFriendRequest(friendRequest: ModelsGolfersGetFriendRequestsFriendRequest, index: number) {
        this.golfersProxy.rejectFriendRequest(friendRequest.sender.golferId, this.currentGolfer.golferId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.friendRequests.splice(index, 1);
            });
    }

    private loadFriendRequests(golfer: ModelsCoreGolfersGolfer) {
        this.golfersProxy.getFriendRequests(golfer.golferId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.friendRequests = response.body;
                });
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onRightMenuButtonClick() {
        this.layoutService.openRightSidebar();
    }

    onMobileTopbarMenuButtonClick() {
        this.layoutService.onTopbarMenuToggle();
    }

    focusSearchInput() {
        setTimeout(() => {
            this.searchInput.nativeElement.focus()
        }, 0);
    }
}
