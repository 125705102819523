import { Component } from '@angular/core';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-practice-activity-history-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class PracticeActivityHistoryLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');
    }
}
