import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../../../shared/server-proxies';
import { MessagesComponent } from '../../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../../shared/ui/base-component.directive';
import {
    GolfClubYardageAnalysisDialogComponent,
    GolfClubYardageAnalysisDialogData
} from '../yardage-analysis-dialog/yardage-analysis-dialog.component';

import {
    ModelsCoreGolfersGolfClub,
    ModelsCoreGolfersGolfClubCategories,
    ModelsCoreGolfersGolfer
} from '../../../../shared/swagger-codegen/models';

interface IGolfClubWithDetails extends ModelsCoreGolfersGolfClub {
    details: string;
}

@Component({
    selector: 'my-golf-clubs-list',
    templateUrl: './golf-clubs-list.component.html',
    standalone: false
})
export class GolfClubsListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private confirmationService: ConfirmationService,
        private dialogService: DialogService,
        private router: Router) {
        super();
    }

    @Input() golfer: ModelsCoreGolfersGolfer;
    @Input() messages: MessagesComponent;
    golfClubs: ModelsCoreGolfersGolfClub[];
    golfClubCategories: SelectItem[] = [];
    selectedGolfClub: ModelsCoreGolfersGolfClub;
    activeDetailsRow: number;

    ngOnInit() {
        this.referenceControllerProxy.getGolfClubCategorySelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.golfClubCategories = response;
                });

        this.taskStarted();
        this.golfersControllerProxy.getGolfClubs(this.golfer.golferId, { includeInactive: true } )
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.golfClubs = response.body;
                    this.addClubDetails();
                });
    }

    editClub(club: ModelsCoreGolfersGolfClub) {
        this.router.navigate(['golfers', this.golfer.golferId, 'golf-club', club.golfClubId]);
    }

    showDetails(index: number) {
        this.activeDetailsRow = index;
    }

    isRowActive(index: number) {
        return this.activeDetailsRow === index;
    }

    hideDetails() {
        this.activeDetailsRow = -1;
    }

    deleteClub(club: ModelsCoreGolfersGolfClub, index: number) {
        const message = `Are you sure you want to delete the ${club.name}?`;

        this.confirmationService.confirm(
            {
                key: 'golf-club-component',
                header: 'Delete Golf Club?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.golfersControllerProxy.removeGolfClub(this.golfer.golferId, club.golfClubId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.golfClubs.splice(index, 1);
                            },
                            response => this.messages.showApiError(response));
                }
            });
    }

    addClub() {
        this.router.navigate(['golfers', this.golfer.golferId, 'golf-club', 'new']);
    }

    shouldShowYardage(club: ModelsCoreGolfersGolfClub) {
        return club.golfClubCategory.golfClubCategoryId !== ModelsCoreGolfersGolfClubCategories.Putter;
    }

    showYardageChart() {
        const data: GolfClubYardageAnalysisDialogData = {
            golfer: this.golfer,
            golfClubs: this.golfClubs
        };

        this.dialogService.open(
            GolfClubYardageAnalysisDialogComponent,
            {
                data: data,
                header: 'Golf Club Yardage Analysis',
                width: '70%'
            });
    }

    private addClubDetails() {
        this.golfClubs.forEach((club: IGolfClubWithDetails) => {
            const details: string[] = [];

            if(club.headBrand) {
                details.push(`${club.headBrand} ${club.headModel || ''}`);
            }

            if(club.shaftBrand) {
                details.push(`Shaft: ${club.shaftBrand} ${club.shaftModel || ''} ${club.shaftFlex || ''}`);
            }

            if(club.length) {
                details.push(`Length: ${club.length}`);
            }

            if(club.lie) {
                details.push(`Lie: ${club.lie}`);
            }

            if(club.loft) {
                details.push(`Loft: ${club.loft}`);
            }

            if(club.swingWeight) {
                details.push(`Swing Weight: ${club.swingWeight}`);
            }

            if(club.gripBrand) {
                details.push(`Grip: ${club.gripBrand} ${club.gripModel || ''}`);
            }

            if(club.purchaseDate) {
                details.push(`Purchased: ${new Date(club.purchaseDate).toLocaleDateString()}`);
            }

            if(details.length > 0) {
                club.details = details.join('<br />');
            }
            else {
                club.details = undefined;
            }
        });
    }
}
