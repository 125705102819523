import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy, PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { PracticeTimeChartDialogService } from '../../practice-time-chart-dialog/practice-time-chart-dialog.service';

import {
    ModelsCorePracticePracticePlan
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-new-practice-session',
    templateUrl: './new-practice-session.component.html',
    styleUrls: [
        './new-practice-session.component.scss'
    ],
    standalone: false
})
export class NewPracticeSessionComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private practiceControllerProxy: PracticeControllerProxy,
        private currentGolfer: CurrentGolferService,
        private practiceTimeChartDialogService: PracticeTimeChartDialogService,
        private router: Router) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    practicePlans: ModelsCorePracticePracticePlan[];
    practicePlanIds: number[] = [];
    private golferId: number;

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.golfersControllerProxy.getPracticePlans(this.golferId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.practicePlans = response.body.filter(plan => plan.isActive);
            });
    }

    showPracticeTimeByCategoryChart(practicePlan: ModelsCorePracticePracticePlan) {
        const header = `${practicePlan.name}`;
        this.practiceTimeChartDialogService.open(header, practicePlan.practiceCategories, practicePlan.practiceTypes);
    }

    createSession() {
        this.taskStarted();

        this.practiceControllerProxy.startPracticeSession(this.practicePlanIds)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const practiceSessionId = response.body.practiceSessionId;
                    const args = {
                        starting: true
                    };
                    this.router.navigate(['/practice/sessions', practiceSessionId, args]);
                });
    }
}
