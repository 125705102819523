import { Component, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { LayoutService } from './service/app.layout.service';

import { SessionService } from '../shared/auth/session.service';
import { ModelsCoreGolfersGolfer } from '../shared/swagger-codegen/models';

@Component({
    selector: 'app-menu-profile',
    templateUrl: './app.menuprofile.component.html',
    styleUrl: './app.menuprofile.component.scss',
    animations: [
        trigger('menu', [
            transition('void => inline', [
                style({ height: 0 }),
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 1, height: '*' })),
            ]),
            transition('inline => void', [
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 0, height: '0' })),
            ]),
            transition('void => overlay', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)'),
            ]),
            transition('overlay => void', [
                animate('.1s linear', style({ opacity: 0 })),
            ]),
        ]),
    ],
    standalone: false
})
export class AppMenuProfileComponent {
    constructor(public layoutService: LayoutService, public el: ElementRef,
        private session: SessionService) {        
    }

    toggleMenu() {
        this.layoutService.onMenuProfileToggle();
    }

    get isHorizontal() {
        return (
            this.layoutService.isHorizontal() && this.layoutService.isDesktop()
        );
    }

    get menuProfileActive(): boolean {
        return this.layoutService.state.menuProfileActive;
    }

    get menuProfilePosition(): string {
        return this.layoutService.config().menuProfilePosition;
    }

    get isTooltipDisabled(): boolean {
        return !this.layoutService.isSlim();
    }

    get currentGolfer(): ModelsCoreGolfersGolfer {
        return this.session.getGolfer();
    }

    get darkModeItemLabel() {
        return `Switch to ${this.isDarkMode ? 'Light' : 'Dark'} Mode`
    }

    get isDarkMode() {
        return this.layoutService.isDarkMode;
    }

    toggleDarkMode() {
        this.layoutService.toggleDarkMode();
    }
}
