import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../../shared/server-proxies';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { StatisticsTabViewIndexes } from '../../statistics-tab-view-indexes';

import {
    ModelsCoreRoundsGolfRoundTypes,
    ModelsCoreStatisticsGoal,
    ModelsCoreStatisticsGoalTypes,
    ModelsCoreStatisticsStatistic,
    ModelsCoreStatisticsStats,
    ModelsWebApiGolfersSaveGoalModel
} from '../../../shared/swagger-codegen/models';

@Component(
    {
    selector: 'my-goal-detail',
    templateUrl: './goal-detail.component.html',
    standalone: false
})
export class GoalDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private referenceProxy: ReferenceControllerProxy,
        private router: Router,
        private currentRoute: ActivatedRoute,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    pageTitle = 'Goal';
    golfers: SelectItem[] = [];
    goalTypes: SelectItem[] = [
        { value: ModelsCoreStatisticsGoalTypes.Annual, label: 'Annual' },
        { value: ModelsCoreStatisticsGoalTypes.Quarterly, label: 'Quarterly' },
        { value: ModelsCoreStatisticsGoalTypes.Monthly, label: 'Monthly' },
        { value: ModelsCoreStatisticsGoalTypes.AllTime, label: 'All Time' }
    ];
    years: SelectItem[] = [];
    quarters: SelectItem[] = [];
    months: SelectItem[] = [];
    golfRoundTypes: SelectItem[] = [
        { value: ModelsCoreRoundsGolfRoundTypes.All, label: 'All Rounds' },
        { value: ModelsCoreRoundsGolfRoundTypes.Competitive, label: 'Only Competitive Rounds' },
        { value: ModelsCoreRoundsGolfRoundTypes.Practice, label: 'Only Practice Rounds' }
    ];
    statistics: SelectItem[] = [];
    selectedGoalType = ModelsCoreStatisticsGoalTypes.Annual;
    selectedStatistic = '';
    goal = {
        golfRoundType: ModelsCoreRoundsGolfRoundTypes.All
    } as ModelsCoreStatisticsGoal;
    private isGoalCopy = false;

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const goalId = parseInt(params['goalId'], 10);
                    this.isGoalCopy = params['copy'] === 'true';
                    this.pageTitle = goalId > 0 ? 'Edit Goal' : 'Create Goal';

                    this.initializeDateOptions();

                    this.referenceProxy.getStatistics()
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            response => {
                                this.loadStatisticSelectItems(response.body);

                                if(goalId > 0) {
                                    this.loadGoal(goalId);
                                }
                            });
                });
    }

    get shouldShowYear() {
        return this.selectedGoalType !== ModelsCoreStatisticsGoalTypes.AllTime;
    }

    get shouldShowQuarter() {
        return this.selectedGoalType === ModelsCoreStatisticsGoalTypes.Quarterly;
    }

    get shouldShowMonth() {
        return this.selectedGoalType === ModelsCoreStatisticsGoalTypes.Monthly;
    }

    onYearChanged() {
        const now = this.getCurrentDate();
        this.setQuarterOptions(now);
        this.setMonthOptions(now);
    }

    getSaveButtonLabel() {
        return this.goal.goalId > 0 ? 'Save Goal' : 'Create Goal';
    }

    saveGoal() {
        const statisticSelections = this.selectedStatistic.split(':');

        this.goal.statistic = {
            statisticId: parseInt(statisticSelections[0], 10)
        } as ModelsCoreStatisticsStatistic;
        this.goal.holesPlayed = parseInt(statisticSelections[1], 10);
        this.goal.golferId = this.currentGolfer.golferId;

        const model: ModelsWebApiGolfersSaveGoalModel = {
            year: this.selectedGoalType !== ModelsCoreStatisticsGoalTypes.AllTime ? this.goal.year : undefined,
            quarter: this.selectedGoalType === ModelsCoreStatisticsGoalTypes.Quarterly ? this.goal.quarter : undefined,
            month: this.selectedGoalType === ModelsCoreStatisticsGoalTypes.Monthly ? this.goal.month : undefined,
            statistic: this.goal.statistic.statisticId as ModelsCoreStatisticsStats,
            golfRoundType: this.goal.golfRoundType,
            holesPlayed: this.goal.holesPlayed,
            target: this.goal.target,
            notes: this.goal.notes,
            isVisibleToFriends: this.goal.isVisibleToFriends
        };
        const goalId = this.isGoalCopy ? -1 : this.goal.goalId;

        this.taskStarted();

        this.golfersProxy.saveGoal(this.currentGolfer.golferId, goalId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.router.navigate(['/statistics', { tab: StatisticsTabViewIndexes.goalsTabIndex }]);
                },
                response => this.messages.showApiError(response));
    }

    private loadGoal(goalId: number) {
        this.taskStarted();

        this.golfersProxy.getGoal(this.currentGolfer.golferId, goalId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.goal = response.body;
                    this.selectedStatistic = `${this.goal.statistic.statisticId}:${this.goal.holesPlayed}`;

                    if(this.goal.month) {
                        this.selectedGoalType = ModelsCoreStatisticsGoalTypes.Monthly;

                        if(this.isGoalCopy) {
                            this.goal.year = this.years[0].value;
                            this.goal.month = this.months[0].value;
                        }
                    }
                    else if(this.goal.quarter) {
                        this.selectedGoalType = ModelsCoreStatisticsGoalTypes.Quarterly;

                        if(this.isGoalCopy) {
                            this.goal.year = this.years[0].value;
                            this.goal.quarter = this.quarters[0].value;
                        }
                    }
                    else if(this.goal.year) {
                        this.selectedGoalType = ModelsCoreStatisticsGoalTypes.Annual;

                        if(this.isGoalCopy) {
                            this.goal.year = this.years[0].value;
                        }
                    }
                    else {
                        this.selectedGoalType = ModelsCoreStatisticsGoalTypes.AllTime;
                    }

                    if(this.goal.statistic.isPercentage) {
                        this.goal.target = this.goal.target * 100.0;
                    }
                },
                response => this.messages.showApiError(response));
    }

    private initializeDateOptions() {
        const now = this.getCurrentDate();
        this.setYearOptions(now);
        this.setQuarterOptions(now);
        this.setMonthOptions(now);
    }

    private setYearOptions(currentDate: Date) {
        const currentMonth = currentDate.getUTCMonth() + 1;
        const currentDay = currentDate.getUTCDate();
        const currentYear = currentDate.getUTCFullYear();

        this.years.length = 0;

        if(currentMonth < 12 || (currentMonth === 12 && currentDay <= 5)) {
            this.years.push({ value: currentYear, label: currentYear.toString() });
        }

        if(currentMonth >= 10) {
            const nextYear = currentYear + 1;
            this.years.push({ value: nextYear, label: nextYear.toString() });
        }

        this.goal.year = this.years[0].value;
    }

    private setQuarterOptions(currentDate: Date) {
        const currentMonth = currentDate.getUTCMonth() + 1;
        const currentYear = currentDate.getUTCFullYear();
        const allQuarters: SelectItem[] = [
            { value: 1, label: 'Q1' },
            { value: 2, label: 'Q2' },
            { value: 3, label: 'Q3' },
            { value: 4, label: 'Q4' }
        ];
        let startingQuarter = 1;

        if(this.goal.year === currentYear) {
            if(currentMonth <= 2) {
                startingQuarter = 1;
            }
            else if(currentMonth <= 5) {
                startingQuarter = 2;
            }
            else if(currentMonth <= 8) {
                startingQuarter = 3;
            }
            else {
                startingQuarter = 4;
            }
        }

        this.quarters.length = 0;

        for(let i = startingQuarter - 1; i < allQuarters.length; i++) {
            this.quarters.push(allQuarters[i]);
        }

        this.goal.quarter = this.quarters[0].value;
    }

    private setMonthOptions(currentDate: Date) {
        const currentMonth = currentDate.getUTCMonth() + 1;
        const currentDay = currentDate.getUTCDate();
        const currentYear = currentDate.getUTCFullYear();
        const allMonths: SelectItem[] = [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' }
        ];
        let startingMonth = 1;

        if(this.goal.year === currentYear) {
            if(currentDay <= 5) {
                startingMonth = currentMonth;
            }
            else {
                startingMonth = currentMonth + 1;
            }
        }

        this.months.length = 0;

        for(let i = startingMonth - 1; i < 12; i++) {
            this.months.push(allMonths[i]);
        }

        this.goal.month = this.months[0].value;
    }

    private loadStatisticSelectItems(statistics: ModelsCoreStatisticsStatistic[]) {
        statistics = statistics.sort((a, b) => {
            if(a.displayName < b.displayName) {
                return -1;
            }
            else if(a.displayName > b.displayName) {
                return 1;
            }
            else {
                return 0;
            }
        });

        for(let i = 0; i < statistics.length; i++) {
            const statistic = statistics[i];

            if(statistic.dependsOnHolesPlayed) {
                this.statistics.push({
                    value: `${statistic.statisticId}:${18}`,
                    label: statistic.displayNamesByHolesPlayed[18] || statistic.displayName
                });
                this.statistics.push({
                    value: `${statistic.statisticId}:${9}`,
                    label: statistic.displayNamesByHolesPlayed[9] || statistic.displayName
                });
            }
            else {
                this.statistics.push({ value: `${statistic.statisticId}:0`, label: statistic.displayName });
            }
        }
    }

    private getCurrentDate() {
        return new Date();
    }
}
