import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { finalize, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { GolferLookupService, IGolferSelectItem } from '../../../shared/golfers/golfer-lookup.service';

import {
    ModelsCorePracticePracticeActivitySummary,
    ModelsWebApiGolfersGetPracticeActivitySummaryArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-activity-summary',
    templateUrl: './practice-activity-summary.component.html',
    standalone: false
})
export class PracticeActivitySummaryComponent extends BaseComponentDirective implements OnInit, AfterViewInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    @ViewChild('activityFilter') activityFilterInput: ElementRef;
    activitySummaries: ModelsCorePracticePracticeActivitySummary[] = [];
    filteredActivitySummaries: ModelsCorePracticePracticeActivitySummary[] = [];
    yearsPracticed: SelectItem[];
    golfers: IGolferSelectItem[];
    year: number;
    summaryForScoreHistoryChart: ModelsCorePracticePracticeActivitySummary;
    chartTitle: string;
    noActivitySummaryMessage = 'No practice activity summaries.';
    golferId: number;
    private activityFilter: string;

    get shouldShowGolferSelector() {
        return this.currentGolfer.isAdmin && !this.summaryForScoreHistoryChart;
    }

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.loadFriends();
        this.loadYearsPracticed();
    }

    ngAfterViewInit() {
        fromEvent(this.activityFilterInput.nativeElement, 'input')
            .pipe(
                map((e: KeyboardEvent) => (e.target as HTMLInputElement).value),
                filter((text: string) => text.length > 2),
                debounceTime(10),
                distinctUntilChanged()
            )
            .subscribe((text: string) => {
                this.activityFilter = text;
                this.filterPracticeActivities();
            });
    }

    getActivityFilterStyle() {
        return {
            display: this.summaryForScoreHistoryChart ? 'none' : undefined
        };
    }

    clearFilter() {
        this.activityFilter = undefined;
        this.filterPracticeActivities();
    }

    onYearChanged() {
        this.loadSummary();
    }
    
    onGolferChanged() {
        this.loadSummary();
    }

    showScoreHistory(summary: ModelsCorePracticePracticeActivitySummary) {
        this.chartTitle = summary.practiceActivity.name;

        if(summary.practiceActivityVariation) {
            this.chartTitle += `: ${summary.practiceActivityVariation.name}`;
        }

        this.summaryForScoreHistoryChart = summary;
    }

    hideScoreHistory() {
        this.summaryForScoreHistoryChart = undefined;
    }
    
    private loadYearsPracticed() {
        this.taskStarted();
        this.golferLookups.getYearsPracticedSelectItems()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                years => {
                    this.yearsPracticed = years;
                    this.year = this.golferLookups.defaultYearPracticed;
                    this.loadSummary();
                });
    }

    private loadFriends() {
        this.taskStarted();
        this.golferLookups.getFriendSelectItems()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                friends => {
                    this.golfers = friends;
                    this.golferId = this.currentGolfer.golferId;
                });
    }

    private loadSummary() {
        const args: ModelsWebApiGolfersGetPracticeActivitySummaryArgs = {
            year: this.year
        };

        this.taskStarted();
        this.golfersControllerProxy.getPracticeActivitiesSummary(this.golferId, args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                response => {
                    this.activitySummaries = response.body;
                    this.filterPracticeActivities();
                });
    }

    private filterPracticeActivities() {
        if(this.activityFilter && this.activityFilter.trim().length > 0) {
            const search = this.activityFilter.trim().toLowerCase();
            this.filteredActivitySummaries = this.activitySummaries
                .filter(s => s.practiceActivity.name.toLowerCase().indexOf(search) !== -1);
        }
        else {
            this.filteredActivitySummaries = this.activitySummaries;
        }
    }
}
