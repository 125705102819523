import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize, map } from 'rxjs/operators';

import { CoursesControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreCoursesCourse,
    ModelsCoursesGetCourseScorecardCourseNine,
    ModelsCoursesGetCourseScorecardCourseScorecard,
    ModelsCoursesGetCourseScorecardHole,
    ModelsCoursesGetCourseScorecardHoleTee,
    ModelsCoursesGetCourseScorecardTee,
    ModelsCoursesGetCourseScorecardTeeRating,
    ModelsWebApiCoursesSaveScorecardModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-course-scorecard',
    templateUrl: './course-scorecard.component.html',
    styleUrls: [
        './course-scorecard.component.scss'
    ],
    standalone: false
})
export class CourseScorecardComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy) {
        super();
    }

    @Input() messages: MessagesComponent;
    @Input() course: ModelsCoreCoursesCourse;
    states: SelectItem[] = [];
    scorecard: ModelsCoursesGetCourseScorecardCourseScorecard;
    hasOneOrMoreNines: boolean;
    hasTwoOrMoreNines: boolean;
    hasThreeOrMoreNines: boolean;
    private originalScorecard: ModelsCoursesGetCourseScorecardCourseScorecard;

    ngOnInit(): void {
        this.loadScorecard();
    }

    getHoles(courseNineIndex: number) {
        let holes: ModelsCoursesGetCourseScorecardHole[] = null;

        if(courseNineIndex < this.scorecard.courseNines.length) {
            holes = this.scorecard.courseNines[courseNineIndex].holes;
        }

        return holes || [];
    }

    getHoleTees(tee: ModelsCoursesGetCourseScorecardTee, courseNineIndex: number) {
        let holeTees: ModelsCoursesGetCourseScorecardHoleTee[] = null;

        if(courseNineIndex < this.scorecard.courseNines.length) {
            const courseNine = this.scorecard.courseNines[courseNineIndex];
            holeTees = courseNine.holeTeesByTeeId[tee.teeId];
        }

        return holeTees || [];
    }

    getTeeRating(courseNine: ModelsCoursesGetCourseScorecardCourseNine, tee: ModelsCoursesGetCourseScorecardTee) {
        return courseNine.teeRatingsByTeeId[tee.teeId] || {} as ModelsCoursesGetCourseScorecardTeeRating;
    }

    getInText() {
        let text = 'In';

        switch(this.scorecard.courseNines.length) {
            case 1:
                text = 'Total';
                break;
            case 3:
                text = 'Out';
                break;
        }

        return text;
    }

    getOutText() {
        let text = 'Out';

        if(this.scorecard.courseNines.length === 1) {
            text = 'Total';
        }

        return text;
    }

    sumYards(tee: ModelsCoursesGetCourseScorecardTee, ...courseNineIndexes: number[]) {
        let sum = 0;

        for(let i = 0; i < courseNineIndexes.length; i++) {
            const holeTees = this.getHoleTees(tee, courseNineIndexes[i]);

            for(let j = 0; j < holeTees.length; j++) {
                const yards = holeTees[j].yards;

                if(yards > 0) {
                    sum += yards;
                }
            }
        }

        return sum;
    }

    sumParForMen(...courseNineIndexes: number[]) {
        return this.sumPar(h => h.parForMen, courseNineIndexes);
    }

    sumParForWomen(...courseNineIndexes: number[]) {
        return this.sumPar(h => h.parForWomen, courseNineIndexes);
    }

    onParForMenChanged(hole: ModelsCoursesGetCourseScorecardHole) {
        if(hole.parForMen > 0 && (!hole.parForWomen || hole.parForWomen < 0)) {
            hole.parForWomen = hole.parForMen;
        }
    }

    onHandicapForMenChanged(hole: ModelsCoursesGetCourseScorecardHole) {
        if(hole.handicapForMen > 0 && (!hole.handicapForWomen || hole.handicapForWomen < 0)) {
            hole.handicapForWomen = hole.handicapForMen;
        }
    }

    onRatingForMenChanged(courseNine: ModelsCoursesGetCourseScorecardCourseNine, tee: ModelsCoursesGetCourseScorecardTee) {
        const teeRating = this.getTeeRating(courseNine, tee);

        if(teeRating.ratingForMen > 0 && (!teeRating.ratingForWomen || teeRating.ratingForWomen < 0)) {
            teeRating.ratingForWomen = teeRating.ratingForMen;
        }
    }

    onSlopeForMenChanged(courseNine: ModelsCoursesGetCourseScorecardCourseNine, tee: ModelsCoursesGetCourseScorecardTee) {
        const teeRating = this.getTeeRating(courseNine, tee);

        if(teeRating.slopeForMen > 0 && (!teeRating.slopeForWomen || teeRating.slopeForWomen < 0)) {
            teeRating.slopeForWomen = teeRating.slopeForMen;
        }
    }

    onTeeStyleChanged(tee: ModelsCoursesGetCourseScorecardTee, args: any) {
        const teeStyle = this.scorecard.teeStyles.find(ts => ts.teeStyleId === args.value);
        tee.teeStyleId = teeStyle.teeStyleId;
        tee.cssClassName = teeStyle.cssClassName;

        if(/^\d+(st|nd|rd|th) Tee$/.test(tee.name)) {
            tee.name = teeStyle.name;
        }
    }

    saveScorecard() {
        this.taskStarted();

        const updates = this.getModifiedScorecardData();

        if(updates) {
            this.coursesProxy.saveScorecard(this.course.courseId, updates)
                .pipe(
                    finalize(
                        () => {
                            this.taskCompleted();
                            this.messages.clearInMilliseconds(5000);
                        }),
                    map(() => this.captureCurrentScorecardData()),
                    this.takeUntilUnsubscribed())
                .subscribe(
                    () => {
                        this.messages.showSuccess('Changes have been saved.');
                    },
                    error => {
                        this.messages.showError(`An error occurred. (${error.status})`);
                    });
        }
        else {
            this.messages.showInfo('Data has not changed so saving is not necessary.');
            this.messages.clearInMilliseconds(5000);
        }
    }

    addTee() {
        this.taskStarted();

        this.coursesProxy.createTee(this.course.courseId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.loadScorecard();
                });
    }

    private loadScorecard() {
        this.taskStarted();

        this.coursesProxy.getScorecard(this.course.courseId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.scorecard = response.body;
                    this.scorecard.teeStyles.forEach(
                        item => {
                            const o = item as any;
                            o.label = item.name;
                            o.value = item.teeStyleId;
                        });
                    this.captureCurrentScorecardData();
                    this.hasOneOrMoreNines = this.scorecard.courseNines.length > 0;
                    this.hasTwoOrMoreNines = this.scorecard.courseNines.length > 1;
                    this.hasThreeOrMoreNines = this.scorecard.courseNines.length > 2;
                });
    }

    private captureCurrentScorecardData() {
        const copiedScorecard = {
            courseNines: this.copyCourseNines(),
            tees: this.copyTees()
        } as ModelsCoursesGetCourseScorecardCourseScorecard;

        this.originalScorecard = copiedScorecard;
    }

    private copyCourseNines() {
        const copied: ModelsCoursesGetCourseScorecardCourseNine[] = [];

        this.scorecard.courseNines.forEach(source => {
            const courseNine: ModelsCoursesGetCourseScorecardCourseNine = {
                courseNineId: source.courseNineId,
                name: source.name,
                holes: this.copyHoles(source),
                teeRatingsByTeeId: this.copyTeeRatingsByTeeId(source),
                holeTeesByTeeId: this.copyHoleTeesByTeeId(source)
            };

            copied.push(courseNine);
        });

        return copied;
    }

    private copyHoles(courseNine: ModelsCoursesGetCourseScorecardCourseNine) {
        const copied: ModelsCoursesGetCourseScorecardHole[] = [];

        courseNine.holes.forEach(
            source => {
                /* eslint-disable id-blacklist */
                const copy: ModelsCoursesGetCourseScorecardHole = {
                    holeId: source.holeId,
                    number: source.number,
                    parForMen: source.parForMen,
                    parForWomen: source.parForWomen,
                    handicapForMen: source.handicapForMen,
                    handicapForWomen: source.handicapForWomen
                };

                copied.push(copy);
            });

        return copied;
    }

    private copyTeeRatingsByTeeId(courseNine: ModelsCoursesGetCourseScorecardCourseNine) {
        const copied: { [key: number]: ModelsCoursesGetCourseScorecardTeeRating } = {};

        Object.keys(courseNine.teeRatingsByTeeId)
            .forEach(
                key => {
                    const teeId = parseInt(key, 10);
                    const source: ModelsCoursesGetCourseScorecardTeeRating = courseNine.teeRatingsByTeeId[teeId];
                    const copy: ModelsCoursesGetCourseScorecardTeeRating = {
                        teeId: source.teeId,
                        courseNineId: source.courseNineId,
                        ratingForMen: source.ratingForMen,
                        ratingForWomen: source.ratingForWomen,
                        slopeForMen: source.slopeForMen,
                        slopeForWomen: source.slopeForWomen
                    };

                    copied[teeId] = copy;
                });

        return copied;
    }

    private copyHoleTeesByTeeId(courseNine: ModelsCoursesGetCourseScorecardCourseNine) {
        const copied: { [key: number]: ModelsCoursesGetCourseScorecardHoleTee[] } = {};

        Object.keys(courseNine.holeTeesByTeeId)
            .forEach(
                key => {
                    const teeId = parseInt(key, 10);
                    const sources: ModelsCoursesGetCourseScorecardHoleTee[] = courseNine.holeTeesByTeeId[teeId];
                    const copies: ModelsCoursesGetCourseScorecardHoleTee[] = [];

                    sources.forEach(
                        source => {
                            const copy: ModelsCoursesGetCourseScorecardHoleTee = {
                                holeId: source.holeId,
                                yards: source.yards
                            };

                            copies.push(copy);
                        });

                    copied[teeId] = copies;
                });

        return copied;
    }

    private copyTees() {
        const copied: ModelsCoursesGetCourseScorecardTee[] = [];

        this.scorecard.tees.forEach(source => {
            const copy: ModelsCoursesGetCourseScorecardTee = {
                teeId: source.teeId,
                name: source.name,
                yards: source.yards,
                teeStyleId: source.teeStyleId,
                cssClassName: source.cssClassName
            };

            copied.push(copy);
        });

        return copied;
    }

    private sumPar(getPar: (hole: ModelsCoursesGetCourseScorecardHole) => number, courseNineIndexes: number[]) {
        let sum = 0;

        for(let i = 0; i < courseNineIndexes.length; i++) {
            const holeRatings = this.getHoles(courseNineIndexes[i]);

            for(let j = 0; j < holeRatings.length; j++) {
                const par = getPar(holeRatings[j]);

                if(par > 0) {
                    sum += par;
                }
            }
        }

        return sum;
    }

    private getModifiedScorecardData() {
        let model = {
            courseNines: [],
            holeRatings: [],
            holeTees: [],
            tees: [],
            teeRatings: []
        } as ModelsWebApiCoursesSaveScorecardModel;

        this.collectModifiedCourseNines(model);
        this.collectModifiedTees(model);

        if(model.courseNines.length === 0
            && model.holeRatings.length === 0
            && model.holeTees.length === 0
            && model.tees.length === 0
            && model.teeRatings.length === 0) {
            model = null;
        }

        return model;
    }

    private collectModifiedCourseNines(model: ModelsWebApiCoursesSaveScorecardModel) {
        this.scorecard.courseNines.forEach(
            (current, index) => {
                const original = this.originalScorecard.courseNines[index];

                if(current.name !== original.name) {
                    model.courseNines.push(
                        {
                            courseNineId: current.courseNineId,
                            name: current.name
                        });
                }

                this.collectModifiedHoleRatings(model, index);
                this.collectModifiedHoleTees(model, index);
                this.collectModifiedTeeRatings(model, index);
            });
    }

    private collectModifiedHoleRatings(model: ModelsWebApiCoursesSaveScorecardModel, courseNineIndex: number) {
        const originalCourseNine = this.originalScorecard.courseNines[courseNineIndex];

        this.scorecard.courseNines[courseNineIndex].holes.forEach(
            (current, index) => {
                const original = originalCourseNine.holes[index];

                if(current.parForMen !== original.parForMen
                    || current.parForWomen !== original.parForWomen
                    || current.handicapForMen !== original.handicapForMen
                    || current.handicapForWomen !== original.handicapForWomen) {
                    model.holeRatings.push(
                        {
                            holeId: current.holeId,
                            parForMen: current.parForMen,
                            parForWomen: current.parForWomen,
                            handicapForMen: current.handicapForMen,
                            handicapForWomen: current.handicapForWomen
                        });
                }
            });
    }

    private collectModifiedHoleTees(model: ModelsWebApiCoursesSaveScorecardModel, courseNineIndex: number) {
        const currentCourseNine = this.scorecard.courseNines[courseNineIndex];
        const originalCourseNine = this.originalScorecard.courseNines[courseNineIndex];

        Object.keys(currentCourseNine.holeTeesByTeeId).forEach(
            key => {
                const teeId = parseInt(key, 10);
                const currentHoleTees: ModelsCoursesGetCourseScorecardHoleTee[] = currentCourseNine.holeTeesByTeeId[teeId];
                const originalHoleTees: ModelsCoursesGetCourseScorecardHoleTee[] = originalCourseNine.holeTeesByTeeId[teeId];

                currentHoleTees.forEach(
                    (current, index) => {
                        const original = originalHoleTees[index];

                        if(current.yards !== original.yards) {
                            model.holeTees.push(
                                {
                                    holeId: current.holeId,
                                    teeId: teeId,
                                    yards: current.yards
                                });
                        }
                    });
            });
    }

    private collectModifiedTeeRatings(model: ModelsWebApiCoursesSaveScorecardModel, courseNineIndex: number) {
        const currentCourseNine = this.scorecard.courseNines[courseNineIndex];
        const originalCourseNine = this.originalScorecard.courseNines[courseNineIndex];

        Object.keys(currentCourseNine.teeRatingsByTeeId).forEach(
            key => {
                const teeId = parseInt(key, 10);
                const current: ModelsCoursesGetCourseScorecardTeeRating = currentCourseNine.teeRatingsByTeeId[teeId];
                const original: ModelsCoursesGetCourseScorecardTeeRating = originalCourseNine.teeRatingsByTeeId[teeId];

                if(current.ratingForMen !== original.ratingForMen
                    || current.ratingForWomen !== original.ratingForWomen
                    || current.slopeForMen !== original.slopeForMen
                    || current.ratingForWomen !== original.ratingForWomen) {
                    model.teeRatings.push(
                        {
                            teeId: current.teeId,
                            courseNineId: current.courseNineId,
                            ratingForMen: current.ratingForMen,
                            ratingForWomen: current.ratingForWomen,
                            slopeForMen: current.slopeForMen,
                            slopeForWomen: current.slopeForWomen
                        });
                }
            });
    }

    private collectModifiedTees(model: ModelsWebApiCoursesSaveScorecardModel) {
        this.scorecard.tees.forEach(
            (current, index) => {
                const original = this.originalScorecard.tees[index];

                if(current.name !== original.name
                    || current.teeStyleId !== original.teeStyleId) {
                    model.tees.push(
                        {
                            teeId: current.teeId,
                            name: current.name,
                            teeStyleId: current.teeStyleId
                        });
                }
            });
    }
}
