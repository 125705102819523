import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy } from '../../shared/server-proxies';
import { GolferLookupService, IGolferSelectItem } from '../../shared/golfers/golfer-lookup.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreCoursesCourseHistory,
    ModelsCoreCoursesHoleHistory,
    ModelsCoreRoundsGolfRoundTypes,
    ModelsWebApiGolfersGetCourseHistoryArgs
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
enum ViewOptions {
    stats = 0,
    strokesGainedVsPro = 1,
    strokesGainedVsScratch = 2
}

@Component({
    selector: 'my-course-history',
    templateUrl: './course-history.component.html',
    styleUrls: [
        './course-history.component.scss'
    ],
    standalone: false
})
export class CourseHistoryComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private currentRoute: ActivatedRoute) {
        super();
    }

    courseHistory: ModelsCoreCoursesCourseHistory[] = [];
    holeHistory: ModelsCoreCoursesHoleHistory[] = [];
    golfers: IGolferSelectItem[];
    yearsPlayed: SelectItem[] = [];
    viewOptions: SelectItem[] = [
        { value: ViewOptions.stats, label: 'Stats' },
        { value: ViewOptions.strokesGainedVsPro, label: 'SG vs Pro' },
        { value: ViewOptions.strokesGainedVsScratch, label: 'SG vs Scratch' }
    ];
    golfRoundTypes: SelectItem[] = [
        { label: 'ALL', value: ModelsCoreRoundsGolfRoundTypes.All },
        { label: 'Competitive', value: ModelsCoreRoundsGolfRoundTypes.Competitive },
        { label: 'Practice', value: ModelsCoreRoundsGolfRoundTypes.Practice }
    ];
    selectedViewOption = ViewOptions.stats;
    args: ModelsWebApiGolfersGetCourseHistoryArgs = {
        golfRoundTypeId: ModelsCoreRoundsGolfRoundTypes.Competitive
    };
    golferId: number;
    courseId: number;
    private courseHistoryByYear: { [year: number]: ModelsCoreCoursesCourseHistory[] } = {};
    private holeHistoryByYear: { [year: number]: ModelsCoreCoursesHoleHistory[] } = {};

    get viewingStats() {
        return this.selectedViewOption === ViewOptions.stats;
    }

    get viewingStrokesGained() {
        return this.selectedViewOption === ViewOptions.strokesGainedVsPro
            || this.selectedViewOption === ViewOptions.strokesGainedVsScratch;
    }

    get viewingStrokesGainedVsPro() {
        return this.selectedViewOption === ViewOptions.strokesGainedVsPro;
    }
    
    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.courseId = parseInt(params['courseId'], 10);

                    this.golferLookups.getFriendSelectItems()
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            friends => {
                                this.golferId = this.golferLookups.defaultFriendId;
                                this.golfers = friends;
                                this.loadCourseHistory();
                            });
                });
    }

    onGolferChanged() {
        this.courseHistoryByYear = {};
        this.holeHistoryByYear = {};
        this.loadCourseHistory();
    }

    onYearChanged() {
        if(this.courseHistoryByYear[this.args.year]) {
            this.loadHistoryForYear();
        }
        else {
            this.loadCourseHistory();
        }
    }

    onGolfRoundTypeChanged() {
        this.loadCourseHistory();
    }

    loadHistoryForYear() {
        this.courseHistory = this.courseHistoryByYear[this.args.year] || [];
        this.holeHistory = this.holeHistoryByYear[this.args.year] || [];
    }

    shouldShowHoleHistoryRowGroupHeader(rowIndex: number) {
        return rowIndex === 0 || rowIndex === 9;
    }

    getBestPossibleScore() {
        let best = 0;

        this.holeHistory.forEach(hole => {
            best += hole.bestStrokes;
        });

        return best;
    }

    getTotalEagles() {
        return this.getHoleHistoryTotal(h => h.eagles);
    }

    getTotalBirdies() {
        return this.getHoleHistoryTotal(h => h.birdies);
    }

    getTotalPars() {
        return this.getHoleHistoryTotal(h => h.pars);
    }

    getTotalBogeys() {
        return this.getHoleHistoryTotal(h => h.bogeys);
    }

    getStrokesGainedOffTee(history: ModelsCoreCoursesHoleHistory) {
        return this.viewingStrokesGainedVsPro
            ? history.averageStrokesGainedOffTeeVsPro
            : history.averageStrokesGainedOffTeeVsScratch;
    }

    getStrokesGainedApproach(history: ModelsCoreCoursesHoleHistory) {
        return this.viewingStrokesGainedVsPro
            ? history.averageStrokesGainedApproachVsPro
            : history.averageStrokesGainedApproachVsScratch;
    }

    getStrokesGainedAroundGreen(history: ModelsCoreCoursesHoleHistory) {
        return this.viewingStrokesGainedVsPro
            ? history.averageStrokesGainedAroundGreenVsPro
            : history.averageStrokesGainedAroundGreenVsScratch;
    }

    getStrokesGainedPutting(history: ModelsCoreCoursesHoleHistory) {
        return this.viewingStrokesGainedVsPro
            ? history.averageStrokesGainedPuttingVsPro
            : history.averageStrokesGainedPuttingVsScratch;
    }

    getStrokesGainedTeeToGreen(history: ModelsCoreCoursesHoleHistory) {
        return this.viewingStrokesGainedVsPro
            ? history.averageStrokesGainedTeeToGreenVsPro
            : history.averageStrokesGainedTeeToGreenVsScratch;
    }

    getStrokesGainedTotal(history: ModelsCoreCoursesHoleHistory) {
        return this.viewingStrokesGainedVsPro
            ? history.averageStrokesGainedTotalVsPro
            : history.averageStrokesGainedTotalVsScratch;
    }

    getHoleScoringChallengeCssClass(history: ModelsCoreCoursesHoleHistory, score: number) {
        const achieved = history.bestScore <= score;
        return this.getScoringChallengeCssClass(score, achieved);
    }

    getHoleScoringChallengeTooltip(history: ModelsCoreCoursesHoleHistory, score: number) {
        const achieved = history.bestScore <= score;
        return this.getScoringChallengeTooltip(score, achieved, true);
    }

    getCourseScoringChallengeCssClass(score: number) {
        const achieved = this.holesNotAchievingScore(score) === 0;
        return this.getScoringChallengeCssClass(score, achieved);
    }

    getCourseScoringChallengeTooltip(score: number) {
        const remainingHoles = this.holesNotAchievingScore(score);
        const achieved = remainingHoles === 0;
        return this.getScoringChallengeTooltip(score, achieved, false, remainingHoles);
    }

    private getScoringChallengeCssClass(score: number, achieved: boolean) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            'birdie-challenge': score === -1,
            'par-challenge': score === 0,
            'bogey-challenge': score === 1,
            achieved: achieved
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        return css;
    }

    private getScoringChallengeTooltip(score: number, achieved: boolean, isHoleChallenge: boolean, remainingHoles = 0) {
        let tooltip = 'The ' + (this.args.year > 0 ? this.args.year.toString() : 'Career');

        switch(score) {
            case -1:
                tooltip += ' Birdie';
                break;
            case 0:
                tooltip += ' Par';
                break;
            case 1:
                tooltip += ' Bogey';
                break;
        }

        const description = isHoleChallenge ? 'hole' : 'course';
        tooltip += ` Challenge has ${(achieved ? 'been' : 'not been')} completed on this ${description}.`;

        if(remainingHoles > 0) {
            tooltip += ` ${remainingHoles} ${(remainingHoles === 1 ? 'hole' : 'holes')} left.`;
        }

        return tooltip;
    }

    private holesNotAchievingScore(score: number) {
        let count = 0;

        this.holeHistory.forEach(history => {
            if(history.bestScore > score) {
                count++;
            }
        });

        return count;
    }

    private getHoleHistoryTotal(getNumber: (hole: ModelsCoreCoursesHoleHistory) => number) {
        let total = 0;

        this.holeHistory.forEach(hole => {
            total += getNumber(hole);
        });

        return total;
    }
    
    private loadCourseHistory() {
        this.taskStarted();
        
        this.golfersProxy.getCourseHistory(this.golferId, this.courseId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    if(this.args.year === undefined) {
                        this.args.year = response.body.year;
                    }

                    this.yearsPlayed = this.yearsPlayedToSelectItemList(response.body.yearsPlayed);
                    
                    this.copyKeys(response.body.courseHistory, this.courseHistoryByYear);
                    this.copyKeys(response.body.holeHistory, this.holeHistoryByYear);
                    this.loadHistoryForYear();
                });
    }

    private yearsPlayedToSelectItemList(yearsPlayed: number[]) {
        const items = yearsPlayed.map(
            year => {
                return {
                    label: year.toString(),
                    value: year
                } as SelectItem;
            });

        items.unshift(
            {
                label: 'ALL',
                value: 0
            });

        return items;
    }

    private copyKeys(source: any, destination: any) {
        Object.keys(source).forEach(key => {
            destination[key] = source[key];
        });
    }
}
