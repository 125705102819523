import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../../shared/server-proxies';
import { GolferLookupService, IGolferSelectItem } from '../../../shared/golfers';
import { ScorecardDialogService } from '../../../shared/rounds/scorecard-dialog/scorecard-dialog.service';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsStatisticsGetAchievementHistoryAchievementHistory,
    ModelsWebApiGolfersGetAchievementHistoryArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-achievement-history',
    templateUrl: './achievement-history.component.html',
    standalone: false
})
export class AchievementHistoryComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private referenceProxy: ReferenceControllerProxy,
        private golferLookups: GolferLookupService,
        private scorecardDialog: ScorecardDialogService,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    golfers: IGolferSelectItem[];
    golferId: number;
    args: ModelsWebApiGolfersGetAchievementHistoryArgs = {};
    achievements: SelectItem[];
    achievementId: number;
    yearsPlayed: SelectItem[];
    achievementHistory: ModelsStatisticsGetAchievementHistoryAchievementHistory[];

    get golfRoundId() {
        return this.args.golfRoundId;
    }

    @Input()
    set golfRoundId(value: number) {
        this.args.golfRoundId = value;
    }

    get isViewingGolfRoundAchievements() {
        return this.golfRoundId > 0;
    }

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const achievementId = parseInt(params['achievementId'], 10);
                    const golferId = parseInt(params['golferId'], 10);
                    const year = parseInt(params['year'], 10);

                    this.achievementId = isNaN(achievementId) ? 1 : achievementId;
                    this.golferId = isNaN(golferId) ? this.golferLookups.defaultFriendId : golferId;
                    this.args.year = isNaN(year) ? this.golferLookups.defaultYearPlayed : year;

                    if(!this.achievements) {
                        this.loadSelectItemLists()
                            .pipe(this.takeUntilUnsubscribed())
                            .subscribe();
                    }

                    this.loadAchievementHistory();
                });
    }

    onAchievementChanged() {
        this.updateRoute();
    }

    onGolferChanged() {
        this.updateRoute();
    }

    onYearChanged() {
        this.updateRoute();
    }

    showScorecardDialog(history: ModelsStatisticsGetAchievementHistoryAchievementHistory) {
        this.scorecardDialog.open(history.golfRoundId, history.teeTime, history.courseName);
    }

    private loadSelectItemLists() {
        const achievementObservable = this.referenceProxy.getAchievementSelectItems();
        const golfersObservable = this.golferLookups.getFriendSelectItems();
        const yearsObservable = this.golferLookups.getYearsPlayedSelectItems();

        return forkJoin([achievementObservable, golfersObservable, yearsObservable])
            .pipe(
                tap(
                    results => {
                        this.achievements = results[0];
                        this.golfers = results[1];
                        this.yearsPlayed = results[2];
                    }));
    }

    private loadAchievementHistory() {
        this.taskStarted();
        this.golfersProxy.getAchievementHistory(this.golferId, this.achievementId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.achievementHistory = response.body;
                });
    }

    private updateRoute() {
        const args: any = {
            golferId: this.golferId,
            year: this.args.year
        };

        this.router.navigate(['/statistics/achievements', this.achievementId, 'history', args]);
    }
}
