/* eslint-disable */
import { NgModule, ErrorHandler, provideAppInitializer } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';

import { ChartsModule } from './charts/charts.module';
import { SharedModule } from './shared/shared.module';
import { CoursesModule } from './courses/courses.module';
import { GolfersModule } from './golfers/golfers.module';
import { PracticeModule } from './practice/practice.module';
import { PublicModule } from './public/public.module';
import { RoundsModule } from './rounds/rounds.module';
import { StatisticsModule } from './statistics/statistics.module';
import { MessagePubSubService } from './shared/core/message-pub-sub.service';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [AppRoutingModule,
        AppLayoutModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        ChartsModule,
        CoursesModule,
        GolfersModule,
        PracticeModule,
        PublicModule,
        RoundsModule,
        StatisticsModule], providers: [
        MessagePubSubService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => {
            const initializerFn = (() => () => { })();
            return initializerFn();
          }),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

}
