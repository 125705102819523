import { Component } from '@angular/core';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

@Component({
    selector: 'my-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    standalone: false
})
export class PrivacyPolicyComponent extends BaseComponentDirective {
}
