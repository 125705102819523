import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-courses-played-pie-chart',
    template: '<p-chart type="pie" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class CoursesPlayedPieChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'pie');
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'pie'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const courseName = tooltipItem.label;
        const roundsPlayedAtCourse = data[tooltipItem.dataIndex];
        const totalRounds = data.reduce((runningTotal, currentValue) => runningTotal + currentValue, 0);
        const percentOfRounds = totalRounds === 0 ? 0 : (roundsPlayedAtCourse / totalRounds) * 100;
        const roundOrRounds = roundsPlayedAtCourse === 1 ? 'Round' : 'Rounds';

        return `${courseName}: ${roundsPlayedAtCourse} ${roundOrRounds} (${percentOfRounds.toFixed(2)} %)`;
    }
}
