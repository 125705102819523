import { Component, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

export type ValidationType = 'required';

@Component({
    selector: 'my-validation-message',
    template: '<p-message *ngIf="invalid" severity="error" [text]="message"></p-message>',
    standalone: false
})
export class ValidationMessageComponent {
    @Input() model: NgModel;
    @Input() message: string;
    private myType: ValidationType;

    get invalid() {
        return this.model && this.model.dirty && this.model.hasError(this.type);
    }

    get type() {
        return this.myType;
    }

    @Input()
    set type(value: ValidationType) {
        this.myType = value;

        if(!this.message) {
            if(value === 'required') {
                this.message = 'This field is required.';
            }
        }
    }
}
