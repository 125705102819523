import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ChartData } from 'chart.js';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { GolferLookupService, IGolferSelectItem } from '../../../shared/golfers';
import { GolfersControllerProxy } from '../../../shared/server-proxies';

import {
    ModelsCoreRoundsGolfRoundTypes,
    ModelsStatisticsGetChartStatisticCharts,
    ModelsStatisticsGetStatisticsStatistic
} from '../../../shared/swagger-codegen/models';

export interface IGenericStatChartData {
    statistic: ModelsStatisticsGetStatisticsStatistic;
    columnHeaders: string[];
}

@Component({
    selector: 'my-statistic-snapshot-chart-dialog',
    templateUrl: './statistic-snapshot-chart-dialog.component.html',
    standalone: false
})
export class StatisticSnapshotChartDialogComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private dynamicDialogConfig: DynamicDialogConfig) {
        super();
    }

    golfers: IGolferSelectItem[] = [];
    golfRoundTypes: SelectItem[] = [
        { label: 'ALL', value: ModelsCoreRoundsGolfRoundTypes.All },
        { label: 'Competitive', value: ModelsCoreRoundsGolfRoundTypes.Competitive },
        { label: 'Practice', value: ModelsCoreRoundsGolfRoundTypes.Practice }
    ];
    golferId: number;
    golfRoundTypeId = ModelsCoreRoundsGolfRoundTypes.All;
    chartData: ChartData;
    statistic: ModelsStatisticsGetStatisticsStatistic;

    get isPercentage() {
        return this.statistic && this.statistic.isPercentage;
    }

    ngOnInit() {
        this.statistic = this.dynamicDialogConfig.data.statistic;
        this.loadSelectLists();
    }

    onGolferChanged() {
        this.loadChart();
    }

    onGolfRoundTypeChanged() {
        this.loadChart();
    }

    private loadSelectLists() {
        this.golferLookups.getFriendSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe((friends: IGolferSelectItem[]) => {
                this.golfers = friends;

                const configData = this.dynamicDialogConfig.data;

                this.golferId = configData.golferId || this.golferLookups.defaultFriendId;
                this.golfRoundTypeId = configData.golfRoundTypeId;

                this.loadChart();
            });
    }

    private loadChart() {
        const statistic: ModelsStatisticsGetStatisticsStatistic = this.dynamicDialogConfig.data.statistic;

        const args: any = {
            chart: ModelsStatisticsGetChartStatisticCharts.StatisticSnapshot,
            statisticId: statistic.statisticId,
            holesPlayed: statistic.holesPlayed,
            year: 0,
            golfRoundType: this.golfRoundTypeId as any
        };

        this.golfersProxy.getChart(this.golferId, args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.chartData = response.body.chart as any;
            });
    }
}
