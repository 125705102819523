import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';
import { TimeSpan } from '../shared/util';

@Component({
    selector: 'my-practice-time-by-category-pie-chart',
    template: '<p-chart type="pie" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class PracticeTimeByCategoryPieChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'pie');
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'pie'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const categoryName = tooltipItem.label;
        const categoryMinutes = data[tooltipItem.dataIndex];
        const totalMinutes = data.reduce((runningTotal, currentValue) => runningTotal + currentValue, 0);
        const percentOfTotal = totalMinutes === 0 ? 0 : (categoryMinutes / totalMinutes) * 100;

        const categoryMinutesTimeSpan = TimeSpan.fromMinutes(categoryMinutes);
        const totalMinutesTimeSpan = TimeSpan.fromMinutes(totalMinutes);

        return [
            ` ${categoryName}: ${categoryMinutesTimeSpan.toString()} Practice Time`,
            ` ${percentOfTotal.toFixed(0)}% of ${totalMinutesTimeSpan.toString()} Total Practice Time.`
        ];
    }
}
