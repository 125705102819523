import { Component, OnInit, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { SessionService } from '../../../shared/auth/session.service';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersDefaultCourseYardageTypes,
    ModelsCoreGolfersGolfer,
    ModelsWebApiGolfersSaveGolferModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-golfer-detail',
    templateUrl: './golfer-detail.component.html',
    standalone: false
})
export class GolferDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private session: SessionService) {
        super();
    }

    @Input() golfer: ModelsCoreGolfersGolfer;
    @Input() messages: MessagesComponent;
    states: SelectItem[] = [];
    genders: SelectItem[] = [];
    defaultCourseYardageTypes: SelectItem[] = [
        { value: ModelsCoreGolfersDefaultCourseYardageTypes.AtMost, label: 'At Most' },
        { value: ModelsCoreGolfersDefaultCourseYardageTypes.AtLeast, label: 'At Least' }
    ];
    
    ngOnInit() {
        // We have to do this to get the dropdowns to set the value correctly.
        const golfer = this.golfer;
        this.golfer = undefined;

        const stateObservable = this.referenceControllerProxy.getStateSelectItems();
        const genderObservable = this.referenceControllerProxy.getGenderSelectItems();

        forkJoin([stateObservable, genderObservable])
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                results => {
                    this.states = results[0];
                    this.genders = results[1];
                    this.golfer = golfer;
                });
    }

    save() {
        this.taskStarted();
        this.messages.clear();

        const model = this.golfer as ModelsWebApiGolfersSaveGolferModel;

        this.golfersControllerProxy.saveGolfer(this.golfer.golferId, model)
            .pipe(
                finalize(
                    () => {
                        this.taskCompleted();
                        this.messages.clearInMilliseconds(5000);
                }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    const currentGolfer = this.session.getGolfer();

                    if(currentGolfer && currentGolfer.golferId === this.golfer.golferId) {
                        this.session.updateGolfer(this.golfer);
                    }
                    this.messages.showSuccess('Changes have been saved.');
                },
                error => {
                    this.messages.showError(`An error occurred. (${error.status})`);
                });
    }
}
