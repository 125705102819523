import { Component } from '@angular/core';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-practice-activity-score-history-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class PracticeActivityScoreHistoryLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');

        this.options.animation = {
            duration: 0
        };
        this.options.plugins.legend = {
            display: false
        };

        this.options.scales.x = {
            type: 'time',
            time: {
                unit: 'month',
                displayFormats: {
                    month: 'MMM'
                }
            },
            distribution: 'linear',
            gridLines: {
                display: true,
                drawOnChartArea: true
            },
            ticks: {
                major: {
                    enabled: false
                },
                maxRotation: 0
            }
        };

        this.options.scales.y = {
            gridLines: {
                drawBorder: false
            },
            scaleLabel: {
                display: true
            },
            ticks: {
                beginAtZero: false
            }
        };
    }
}
