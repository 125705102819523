import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-scoring-pie-chart',
    template: '<p-chart type="pie" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class ScoringPieChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'pie');

        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'pie'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const label = tooltipItem.label;
        const percent = data[tooltipItem.dataIndex];

        return `${label.replace(' %', '')}: ${percent.toFixed(2)} %`;
    }
}
