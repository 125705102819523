import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponentDirective } from '../shared/ui/base-component.directive';

@Component({
    selector: 'my-statistics-tab-view',
    templateUrl: './statistics-tab-view.component.html',
    standalone: false
})
export class StatisticsTabViewComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    activeTabIndex: number;

    ngOnInit() {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.activeTabIndex = parseInt(params['tab'], 10) || 0;
                });
    }

    onActiveTabChanged(e: any) {
        const params = {
            tab: e.index
        };

        this.router.navigate(['/statistics', params]);
    }
}
