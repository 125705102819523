import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { GolfersControllerProxy, PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';

import {
    ModelsCorePracticePracticeActivity,
    ModelsCorePracticePracticeCategories,
    ModelsCorePracticePracticeTypes,
    ModelsWebApiGolfersGetPracticeActivitiesArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-activity-list',
    templateUrl: './practice-activity-list.component.html',
    standalone: false
})
export class PracticeActivityListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private practiceControllerProxy: PracticeControllerProxy,
        private confirmationService: ConfirmationService,
        private currentGolfer: CurrentGolferService,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    @Input() enableAddingToPlan: boolean;
    @Output() activityAdded = new EventEmitter<ModelsCorePracticePracticeActivity>();
    practiceActivities: ModelsCorePracticePracticeActivity[];
    practiceTypes: SelectItem[] = [];
    practiceCategories: SelectItem[] = [];
    args = {} as ModelsWebApiGolfersGetPracticeActivitiesArgs;
    noActivitiesMessage = 'No practice activities.';
    private golferId: number;

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.taskStarted();
        this.loadSelectItemLists()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                () => {
                    this.currentRoute.params
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            params => {
                                this.args.practiceCategory = parseInt(params['categoryId'], 10) as ModelsCorePracticePracticeCategories
                                    || ModelsCorePracticePracticeCategories.Putting;
                                this.args.practiceType = parseInt(params['typeId'], 10) as ModelsCorePracticePracticeTypes
                                    || ModelsCorePracticePracticeTypes.BlockPractice;
                                this.loadPracticeActivities();
                            });
                });
    }

    onPracticeTypeChanged() {
        this.updateData();
    }

    onPracticeCategoryChanged() {
        this.updateData();
    }

    deletePracticeActivity(practiceActivity: ModelsCorePracticePracticeActivity, index: number) {
        const message = `Are you sure you want to delete ${practiceActivity.name}?`;

        this.confirmationService.confirm(
            {
                key: 'practice-activity-list-component',
                header: 'Delete Practice Activity?',
                message: message,
                accept: () => {
                    this.taskStarted();
                    this.practiceControllerProxy.deletePracticeActivity(practiceActivity.practiceActivityId)
                        .pipe(
                            finalize(() => this.taskCompleted()),
                            this.takeUntilUnsubscribed())
                        .subscribe(
                            () => {
                                this.practiceActivities.splice(index, 1);
                            },
                            response => this.messages.showApiError(response));
                }
            });
    }

    addPracticeActivityToPlan(practiceActivity: ModelsCorePracticePracticeActivity) {
        this.activityAdded.emit(practiceActivity);
    }

    private loadSelectItemLists() {
        const practiceTypeObservable = this.practiceControllerProxy.getPracticeTypes();
        const practiceCategoryObservable = this.practiceControllerProxy.getPracticeCategories();

        return forkJoin([practiceTypeObservable, practiceCategoryObservable])
            .pipe(
                tap(
                    results => {
                        const practiceTypes = results[0].body;
                        const practiceCategories = results[1].body;

                        this.practiceTypes = practiceTypes.map(
                            t => {
                                return {
                                    value: t.practiceTypeId,
                                    label: t.name
                                };
                            });

                        this.practiceCategories = practiceCategories.map(
                            c => {
                                return {
                                    value: c.practiceCategoryId,
                                    label: c.name
                                };
                            });

                        this.args.practiceType = this.practiceTypes.length > 0 ? this.practiceTypes[0].value : 0;
                        this.args.practiceCategory = this.practiceCategories.length > 0 ? this.practiceCategories[0].value : 0;
                    }));
    }

    private loadPracticeActivities() {
        this.taskStarted();
        this.golfersControllerProxy.getPracticeActivities(this.golferId, this.args)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                response => {
                    this.practiceActivities = response.body;
                    this.setNoActivitiesMessage();
                });
    }

    private setNoActivitiesMessage() {
        if(this.practiceTypes.length > 0 && this.practiceCategories.length > 0) {
            const typeLabel = this.practiceTypes.find(t => t.value === this.args.practiceType).label;
            const categoryLabel = this.practiceCategories.find(c => c.value === this.args.practiceCategory).label;
            this.noActivitiesMessage = `No ${typeLabel} Activities for ${categoryLabel}.`;
        }
    }

    private updateData() {
        if(this.enableAddingToPlan) {
            this.loadPracticeActivities();
        }
        else {
            this.updateUrlParams();
        }
    }

    private updateUrlParams() {
        const currentParams = this.currentRoute.snapshot.params;
        const newParams: any = {};

        Object.keys(currentParams).forEach(key => {
            newParams[key] = currentParams[key];
        });

        newParams['categoryId'] = this.args.practiceCategory;
        newParams['typeId'] = this.args.practiceType;

        this.router.navigate([newParams]);
    }
}
