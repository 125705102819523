import { Component, OnInit, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersGolfer,
    ModelsCoreGolfersReminderPreference,
    ModelsCoreSharedNotificationsNotificationPreference,
    ModelsCoreSharedNotificationsNotifications,
    ModelsCoreSharedNotificationsNotificationTypes,
    ModelsWebApiGolfersSaveNotificationsModel,
    ModelsWebApiGolfersSaveNotificationPreferenceModel,
    ModelsWebApiGolfersSaveReminderPreferenceModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-notification-preferences',
    templateUrl: './notification-preferences.component.html',
    standalone: false
})
export class NotificationPreferencesComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy) {
        super();
    }

    @Input() golfer: ModelsCoreGolfersGolfer;
    @Input() messages: MessagesComponent;
    notificationPreferences: ModelsCoreSharedNotificationsNotificationPreference[];
    reminderPreferences: ModelsCoreGolfersReminderPreference[];
    notificationTypes: SelectItem[] = [
        { value: ModelsCoreSharedNotificationsNotificationTypes.None, label: 'None' },
        { value: ModelsCoreSharedNotificationsNotificationTypes.Email, label: 'Email' }
    ];
    shouldWarnOfMissingPhone: boolean;
    missingPhoneWarningMessage: string;
    private reminderPreference: ModelsCoreSharedNotificationsNotificationPreference;

    get reminderPreferenceEnabled() {
        return this.reminderPreference?.notificationTypeId !== ModelsCoreSharedNotificationsNotificationTypes.None;
    }

    ngOnInit() {
        this.taskStarted();
        this.golfersControllerProxy.getNotificationPreferences(this.golfer.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    if(response.body.hasAppleDevice) {
                        this.notificationTypes.splice(1, 0, {
                            value: ModelsCoreSharedNotificationsNotificationTypes.MobilePushNotification,
                            label: 'Apple Push Notification'
                        });
                    }

                    this.notificationPreferences = response.body.notificationPreferences;
                    this.reminderPreferences = response.body.reminderPreferences;
                    this.reminderPreference = this.notificationPreferences
                        .find(p => p.notificationId === ModelsCoreSharedNotificationsNotifications.Reminders);
                    this.checkNoPhoneWarningMessage();
                });
    }

    onNotificationPreferenceSelected() {
        this.checkNoPhoneWarningMessage();
    }

    savePreferences() {
        const model = this.createSaveModel();

        this.taskStarted();
        this.golfersControllerProxy.saveNotificationPreferences(this.golfer.golferId, model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.messages.showSuccess('Preferences have been saved.');
                    this.messages.clearInMilliseconds(5000);
                },
                response => this.messages.showApiError(response)
            );
    }

    private checkNoPhoneWarningMessage() {
        const textMessagePreferences = this.notificationPreferences
            .filter(p => p.notificationTypeId === ModelsCoreSharedNotificationsNotificationTypes.SMS);

        if(textMessagePreferences.length > 0) {
            const notificationLabel = textMessagePreferences.length === 1 ? 'notification' : 'notifications';
            this.missingPhoneWarningMessage = `${textMessagePreferences.length} ${notificationLabel} has a delivery method of Text Message`
                + ' but your profile does not have a phone number. These notifications will not be delivered until a phone number has '
                + 'been provided.';
        }

        this.shouldWarnOfMissingPhone = textMessagePreferences.length > 0 && !this.golfer.phone;
    }

    private createSaveModel() {
        const model = {} as ModelsWebApiGolfersSaveNotificationsModel;

        model.notificationPreferences = this.notificationPreferences
            .map(p => ({
                notification: p.notificationId,
                notificationType: p.notificationTypeId
            } as ModelsWebApiGolfersSaveNotificationPreferenceModel));
        model.reminderPreferences = this.reminderPreferences
            .map(p => ({
                reminderTypeId: p.reminderTypeId,
                sunday: p.sunday,
                monday: p.monday,
                tuesday: p.tuesday,
                wednesday: p.wednesday,
                thursday: p.thursday,
                friday: p.friday,
                saturday: p.saturday,
                days: p.days
            } as ModelsWebApiGolfersSaveReminderPreferenceModel));

        return model;
    }
}
