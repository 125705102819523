import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { CurrentGolferService } from '../../../shared/golfers/current-golfer.service';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { PracticeActivityHistoryChartDialogService }
    from '../../practice-activity-history-chart-dialog/practice-activity-history-chart-dialog.service';

import {
    ModelsCorePracticePracticeScoreQualifiers,
    ModelsWebApiPracticeSavePracticeActivityModel,
    ModelsWebApiPracticeSavePracticeActivityVariationModel
} from '../../../shared/swagger-codegen/models';

export interface SelectItemWithDescription extends SelectItem {
    description: string;
}

@Component({
    selector: 'my-practice-activity-detail',
    templateUrl: './practice-activity-detail.component.html',
    standalone: false
})
export class PracticeActivityDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private practiceControllerProxy: PracticeControllerProxy,
        private confirmationService: ConfirmationService,
        private practiceActivityHistoryChartDialogService: PracticeActivityHistoryChartDialogService,
        private currentGolfer: CurrentGolferService,
        private currentRoute: ActivatedRoute,
        private location: Location) {
        super();
    }

    @ViewChild(NgForm) practiceActivityForm: NgForm;
    @ViewChild(MessagesComponent) messages: MessagesComponent;
    model: ModelsWebApiPracticeSavePracticeActivityModel;
    practiceTypes: SelectItemWithDescription[] = [];
    practiceCategories: SelectItem[] = [];
    practiceScoreQualifiers: SelectItem[] = [];
    isEditing: boolean;
    practiceTypeDescription: string;
    private practiceActivityId: number;
    private golferId: number;

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;

        this.taskStarted();
        this.loadSelectItemLists()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.currentRoute.params
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            params => {
                                this.practiceActivityId = parseInt(params['practiceActivityId'], 10) || 0;
                                this.isEditing = this.practiceActivityId > 0;

                                if(this.practiceActivityId > 0) {
                                    this.loadPracticeActivity();
                                }
                                else {
                                    this.createNewPracticeActivityModel();
                                    this.taskCompleted();
                                }
                            });
            });
    }

    isScorable() {
        return this.model.practiceScoreQualifier !== ModelsCorePracticePracticeScoreQualifiers.None;
    }

    save() {
        this.savePracticeActivity()
            .subscribe(() => this.location.back());
    }

    saveAndAddAnother() {
        this.savePracticeActivity()
            .subscribe(() => {
                this.createNewPracticeActivityModel();
                this.practiceActivityForm.reset();
            });
    }

    addActivityVariation() {
        this.model.practiceActivityVariations.push({} as ModelsWebApiPracticeSavePracticeActivityVariationModel);
    }

    onPracticeTypeChanged() {
        this.setPracticeTypeDescription();
    }

    deleteActivityVariation(activityVariation: ModelsWebApiPracticeSavePracticeActivityVariationModel, index: number) {
        const variationName = activityVariation.name ? activityVariation.name : 'this variation';
        const message = `Are you sure you want to remove ${variationName} from this activity?`;

        this.confirmationService.confirm(
            {
                key: 'practice-activity-detail-component',
                header: 'Delete Activity Variation?',
                message: message,
                accept: () => {
                    if(activityVariation.practiceActivityVariationId > 0) {
                        this.taskStarted();
                        this.practiceControllerProxy.deletePracticeActivityVariation(activityVariation.practiceActivityVariationId)
                            .pipe(
                                finalize(() => this.taskCompleted()),
                                this.takeUntilUnsubscribed())
                            .subscribe(
                                () => {
                                    this.model.practiceActivityVariations.splice(index, 1);
                                },
                                response => this.messages.showApiError(response));
                    }
                    else {
                        this.model.practiceActivityVariations.splice(index, 1);
                    }
                }
            });
    }

    shouldShowScoringHistoryChart(activityVariation?: ModelsWebApiPracticeSavePracticeActivityVariationModel) {
        return this.practiceActivityId > 0
            && this.isScorable()
            && (!activityVariation || activityVariation.practiceActivityVariationId > 0);
    }

    showScoringHistoryChart(activityVariation?: ModelsWebApiPracticeSavePracticeActivityVariationModel) {
        let header = this.model.name;
        let practiceActivityVariationId: number;

        if(activityVariation) {
            header += `: ${activityVariation.name}`;
            practiceActivityVariationId = activityVariation.practiceActivityVariationId;
        }

        this.practiceActivityHistoryChartDialogService.open(
            header,
            this.practiceActivityId,
            practiceActivityVariationId);
    }

    private loadSelectItemLists() {
        const practiceTypeObservable = this.practiceControllerProxy.getPracticeTypes();
        const practiceCategoryObservable = this.practiceControllerProxy.getPracticeCategories();
        const practiceScoreQualifierObservable = this.practiceControllerProxy.getPracticeScoreQualifiers();

        return forkJoin([practiceTypeObservable, practiceCategoryObservable, practiceScoreQualifierObservable])
            .pipe(
                tap(
                    results => {
                        const practiceTypes = results[0].body;
                        const practiceCategories = results[1].body;
                        const practiceScoreQualifiers = results[2].body;

                        this.practiceTypes = practiceTypes.map(
                            t => {
                                return {
                                    value: t.practiceTypeId,
                                    label: t.name,
                                    description: t.description
                                };
                            });

                        this.practiceCategories = practiceCategories.map(
                            c => {
                                return {
                                    value: c.practiceCategoryId,
                                    label: c.name
                                };
                            });

                        this.practiceScoreQualifiers = practiceScoreQualifiers.map(
                            s => {
                                return {
                                    value: s.practiceScoreQualifierId,
                                    label: s.name
                                };
                            });
                    }));
    }

    private loadPracticeActivity() {
        this.practiceControllerProxy.getPracticeActivity(this.practiceActivityId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const activity = response.body;

                    this.model = activity as any;
                    this.model.practiceCategory = activity.practiceCategory.practiceCategoryId as any;
                    this.model.practiceType = activity.practiceType.practiceTypeId as any;
                    this.model.practiceScoreQualifier = activity.practiceScoreQualifierId as any;
                    this.model.scoringRules = activity.scoringRules;
                    this.model.startingScore = activity.startingScore || 0;
                    this.setPracticeTypeDescription();
                });
    }

    private savePracticeActivity() {
        this.taskStarted();

        if(!this.isScorable()) {
            this.model.scoringRules = undefined;
        }

        if(this.practiceActivityId > 0) {
            return this.practiceControllerProxy.savePracticeActivity(this.practiceActivityId, this.model)
                .pipe(
                    finalize(() => this.taskCompleted()),
                    this.takeUntilUnsubscribed());
        }
        else {
            return this.practiceControllerProxy.addPracticeActivity(this.model)
                .pipe(
                    finalize(() => this.taskCompleted()),
                    this.takeUntilUnsubscribed());
        }
    }

    private createNewPracticeActivityModel() {
        this.practiceTypeDescription = undefined;

        this.model = {
            golferId: this.golferId,
            practiceActivityVariation: [],
            startingScore: 0
        } as any;
    }

    private setPracticeTypeDescription() {
        if(this.practiceTypes.length > 0) {
            this.practiceTypeDescription = this.practiceTypes.find(t => t.value === this.model.practiceType).description;
        }
    }
}
