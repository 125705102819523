import { Component, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../ui/base-component.directive';

interface DecisionTreeNode {
    question: string;
    answer?: string;
    yes?: DecisionTreeNode | string;
    no?: DecisionTreeNode | string;
}

@Component({
    selector: 'my-tee-shot-decision-tree',
    /* eslint-disable max-len */
    template: `
        <ol>
            <li *ngFor="let node of nodes; index as i" style="margin-bottom: 15px;">
                {{node.question}}
                <p style="margin-top: 10px;">
                    <span style="margin-right: 15px">
                        <p-radioButton name="group-{{i}}" value="yes" [(ngModel)]="node.answer" label="Yes" styleClass="yes-button" (onClick)="updateNodes();"></p-radioButton>
                    </span>
                    <p-radioButton name="group-{{i}}" value="no" [(ngModel)]="node.answer" label="No" (onClick)="updateNodes();"></p-radioButton>
                </p>
            </li>
            <strong>{{decision}}</strong>
        </ol>        
    `
    /* eslint-enable max-len */
    ,
    standalone: false
})
export class TeeShotDecisionTreeComponent extends BaseComponentDirective implements OnInit {

    nodes: DecisionTreeNode[] = [];
    decision?: string;
    private decisionTree: DecisionTreeNode;
    
    ngOnInit() {
        this.reset();
    }

    reset() {
        this.createDecisionTree();
        this.updateNodes();
    }

    private createDecisionTree() {
        /* eslint-disable max-len */
        this.decisionTree = {
            question: 'Do you have at least least 65 yards between PENALTY hazards?',
            yes: {
                question: 'Is there trouble that pinches to less than 40 yards where your drive will land or roll?',
                yes: {
                    question: 'Can you carry the trouble on one side with the Driver?',
                    yes: 'Go with Driver aimed slightly away from the trouble that is still in play.',
                    no: {
                        question: 'Will a 3 Wood also reach the pinching trouble?',
                        yes: 'Go with Driver unless you can go with less than 3 Wood and still have a wedge into the green.',
                        no: 'Go with 3 Wood.'
                    }
                },
                no: 'Go with Driver.'
            },
            no: {
                question: 'Does a 3 Wood remove a hazard on one side by staying short of it?',
                yes: 'Go with 3 Wood aimed approximately 30 yards away from the in play hazard or centered if it removes both hazards.',
                no: {
                    question: 'Does dropping down from 3 Wood to your next shortest club remove a hazard?',
                    yes: 'It\'s probably that club aimed slightly away from trouble and 25 yards away from PENALTY hazards.',
                    no: 'This is a very difficult hole that will just require hitting a great shot. Consider all options other than Driver and decide based on what each club will leave you for your 2nd shot.'
                }
            }
        };
        /* eslint-enable max-len */
    }

    updateNodes() {
        const updatedNodes: DecisionTreeNode[] = [];
        this.decision = undefined;
        this.evaluateDecisionTree(updatedNodes, this.decisionTree);
        this.nodes = updatedNodes;
    }

    private evaluateDecisionTree(nodes: DecisionTreeNode[], current: DecisionTreeNode) {
        nodes.push(current);

        if(current.answer === undefined) {
            return;
        }

        const next = current[current.answer];

        if(typeof next === 'string') {
            this.decision = next;
        }
        else if(typeof next === 'object') {
            this.evaluateDecisionTree(nodes, next as DecisionTreeNode);
        }
    }
}
