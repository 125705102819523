import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CoursesControllerProxy } from '../server-proxies/courses-controller-proxy.service';
import { BaseComponentDirective } from '../ui/base-component.directive';

@Component({
    selector: 'my-course-name',
    template: '{{courseName}}',
    standalone: false
})
export class CourseNameComponent extends BaseComponentDirective implements OnInit {
    constructor(private coursesProxy: CoursesControllerProxy, private currentRoute: ActivatedRoute) {
        super();
    }

    @Input()
    courseId: number;
    courseName: string;

    ngOnInit(): void {
        if(!this.courseId) {
            this.currentRoute.params
                .pipe(this.takeUntilUnsubscribed())
                .subscribe(
                    params => {
                        this.courseId = parseInt(params['courseId'], 10);
                    });
        }

        if(this.courseId) {
            this.coursesProxy.getCourse(this.courseId)
                .pipe(this.takeUntilUnsubscribed())
                .subscribe(
                    response => {
                        this.courseName = response.body.name;
                    });
        }
    }
}
