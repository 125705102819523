import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-golf-club-yardage-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class GolfClubYardageLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');
        this.options.scales.x.ticks = {
            minRotation: 45,
            maxRotation: 45
        };
        this.options.scales.y.beginAtZero = false;
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const labels = tooltipItem.chart.data.labels;
        const data = tooltipItem.dataset.data as number[];
        const i = tooltipItem.dataIndex;
        const tooltipLines = [
            `${data[i]} Yards`
        ];

        if(i > 0) {
            const previousYardage = data[i - 1];
            tooltipLines.push(`${data[i] - previousYardage} Yard Gap between ${labels[i - 1]} and ${labels[i]}`);
        }

        return tooltipLines;
    }
}
