import { Component, Input } from '@angular/core';
import { BaseComponentDirective } from '../../ui/base-component.directive';

import {
    ModelsCoreCoursesHoleHistory
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-hole-historical-stats',
    templateUrl: './hole-historical-stats.component.html',
    standalone: false
})
export class HoleHistoricalStatsComponent extends BaseComponentDirective {
    @Input() allTimeHistory: ModelsCoreCoursesHoleHistory;
    @Input() mostRecentYearHistory: ModelsCoreCoursesHoleHistory;

}
