import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { CoursesControllerProxy, ReferenceControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { CourseDetailTabViewIndexes } from '../detail/course-detail-tab-view-indexes';

import {
    ModelsCoreSharedState,
    ModelsWebApiCoursesCreateCourseModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-new-course',
    templateUrl: './new-course.component.html',
    standalone: false
})
export class NewCourseComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private router: Router) {
        super();
    }

    course: ModelsWebApiCoursesCreateCourseModel = {
        teeCount: 5,
        holeCount: 18
    } as ModelsWebApiCoursesCreateCourseModel;
    states: SelectItem[] = [];
    timeZones: SelectItem[] = [];
    teeCounts: SelectItem[] = [];
    holeCounts: SelectItem[] = [
        { value: '9', label: '9 Holes' },
        { value: '18', label: '18 Holes' },
        { value: '27', label: '27 Holes' }
    ];

    ngOnInit(): void {
        for(let i = 0; i < 10; i++) {
            const count = i + 1;
            this.teeCounts.push({ value: count.toString(), label: count.toString() });
        }

        this.referenceControllerProxy.getStateSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.states = response;
                });
        this.referenceControllerProxy.getTimeZoneSelectItems()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.timeZones = response;
                });
    }

    onNameChanged() {
        if(this.course && this.course.name.length <= 20) {
            if(!this.course.shortName || this.course.shortName.length === 0) {
                this.course.shortName = this.course.name;
            }
        }
    }

    onStateChanged() {
        const item = this.states.find(s => s.value === this.course.stateCode);

        if(item) {
            const state: ModelsCoreSharedState = (item as any).model;

            if(state.timeZoneIds && state.timeZoneIds.length > 0 && state.timeZoneIds.indexOf(this.course.timeZoneId) === -1) {
                this.course.timeZoneId = state.timeZoneIds[state.timeZoneIds.length - 1];
            }
        }
    }

    createCourse() {
        this.taskStarted();
        return this.coursesProxy.createCourse(this.course)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const params = {
                        tab: CourseDetailTabViewIndexes.scorecardTabIndex
                    };
                    this.router.navigate(['courses', response.body.courseId, params]);
                });
    }
}
