import { Component, OnInit, Input } from '@angular/core';
import { ChartData } from 'chart.js';
import { chartColors } from '../../../charts';
import { PracticeControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsCorePracticePracticeActivity,
    ModelsCorePracticePracticeActivityVariation,
    ModelsCorePracticePracticeSessionActivity,
    ModelsWebApiPracticeGetPracticeActivityHistoryArgs
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-practice-activity-score-history-chart',
    templateUrl: './practice-activity-score-history-chart.component.html',
    standalone: false
})
export class PracticeActivityScoreHistoryChartComponent extends BaseComponentDirective implements OnInit {
    constructor(private practiceControllerProxy: PracticeControllerProxy) {
        super();
    }

    @Input() practiceActivity: ModelsCorePracticePracticeActivity;
    @Input() practiceActivityVariation: ModelsCorePracticePracticeActivityVariation;
    @Input()
    set year(year: number) {
        this.myYear = year;

        if(this.initialized) {
            this.loadPracticeActivities();
        }
    }

    get year() {
        return this.myYear;
    }

    chartData: ChartData;
    private myYear = 0;
    private initialized = false;

    ngOnInit() {
        this.initialized = true;
        this.loadPracticeActivities();
    }

    private loadPracticeActivities() {
        this.taskStarted();
        const args: ModelsWebApiPracticeGetPracticeActivityHistoryArgs = {
            practiceActivityVariationId: this.practiceActivityVariation
                ? this.practiceActivityVariation.practiceActivityVariationId
                : undefined,
            year: this.year,
            onlyScored: true
        };

        this.practiceControllerProxy.getPracticeActivityHistory(this.practiceActivity.practiceActivityId, args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.chartData = this.mapModelsToChartData(response.body);
            });
    }

    private mapModelsToChartData(sessionActivities: ModelsCorePracticePracticeSessionActivity[]) {
        const chartData = this.initializeChartData();
        const data: any[] = this.year < 1 ? [] : [
            {
                t: new Date(this.year, 0, 1).valueOf(),
                y: null
            },
            {
                t: new Date(this.year, 11, 31).valueOf(),
                y: null
            }
        ];
        
        sessionActivities.forEach(sessionActivity => {
            data.push({
                t: new Date(sessionActivity.started).valueOf(),
                y: sessionActivity.score
            });
        });
        
        chartData.datasets[0].data = data;

        return chartData;
    }

    private initializeChartData() {
        return {
            datasets: [
                {
                    label: 'Score',
                    backgroundColor: chartColors[0],
                    borderColor: chartColors[0],
                    data: [],
                    fill: false,
                    pointRadius: 1,
                    pointHitRadius: 5,
                    borderWidth: 2
                }
            ],
            labels: []
        } as ChartData;
    }
}
