/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../shared/core/configuration.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    standalone: false
})
export class AppMenuComponent implements OnInit {

    model: any[];
    upgradeItem: any;

    constructor(private configurationService: ConfigurationService) {}

    ngOnInit() {
        this.model = [
            { label: 'Home', icon: 'fas fa-home', routerLink: ['/'] },
            { label: 'Golf Rounds', icon: 'fas fa-list', routerLink: ['/rounds'] },
            { label: 'Statistics', icon: 'fas fa-chart-bar', routerLink: ['/statistics'] },
            { label: 'Practice', icon: 'fas fa-crosshairs', routerLink: ['/practice'] },
            { label: 'Courses', icon: 'fas fa-flag', routerLink: ['/courses'] },
            { label: 'Golfers', icon: 'fas fa-users', routerLink: ['/golfers'] }
        ];

        this.configurationService.versionUpdates.subscribe(update => {
            this.upgradeItem = {
                label: `Update to v${update.newVersion}`,
                icon: 'update',
                url: '#'
            };
        });
    }

    reloadPage() {
        window.location.reload();
    }
}
