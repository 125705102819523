import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';
import { formatHandicap } from '../shared/ui';

@Component({
    selector: 'my-handicap-history-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class HandicapHistoryLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');
        this.options.scales.y.beginAtZero = false;
        this.options.scales.y.ticks.callback = this.getTick.bind(this);

        this.options.scales.x = {
            type: 'timeseries',
            time: {
                unit: 'year',
                displayFormats: {
                    quarter: 'YYYY'
                }
            },
            offset: true,
            ticks: {
                major: {
                    enabled: false
                },
                source: 'data',
                autoSkip: true,
                autoSkipPadding: 0,
                minRotation: 45,
                maxRotation: 45,
                sampleSize: 100
            }
        };

        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTick(value: any) {
        return formatHandicap(value);
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataSet = tooltipItem.dataset;
        const data = tooltipItem.dataset.data as number[];
        const tooltips = (dataSet as any).tooltips;

        return tooltips ? tooltips[tooltipItem.dataIndex] : formatHandicap(data[tooltipItem.dataIndex]);
    }
}
