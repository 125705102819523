import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy, RoundsControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService, GolferLookupService, IGolferSelectItem } from '../../shared/golfers';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreRoundsGolfRoundTypes,
    ModelsCoreSharedChartingChartDataBase,
    ModelsCoreStatisticsStatisticComparisons,
    ModelsStatisticsGetChartGetChartResponse,
    ModelsStatisticsGetChartStatisticCharts,
    ModelsWebApiGolfersGetStatisticsChartArgs
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-statistic-charts',
    templateUrl: './statistic-charts.component.html',
    standalone: false
})
export class StatisticChartsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private golferLookups: GolferLookupService,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    @Input()
    golfRoundId: number;
    chartData: ModelsCoreSharedChartingChartDataBase;
    golfers: IGolferSelectItem[];
    response: ModelsStatisticsGetChartGetChartResponse;
    golferId: number;
    args = {
        chart: ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleOrder,
        comparison: ModelsCoreStatisticsStatisticComparisons.Friends
    } as ModelsWebApiGolfersGetStatisticsChartArgs;
    yearsPlayed: SelectItem[];
    comparisons: SelectItem[] = [
        { label: 'Friends', value: ModelsCoreStatisticsStatisticComparisons.Friends },
        { label: 'Years', value: ModelsCoreStatisticsStatisticComparisons.MostRecentYears },
        { label: 'Quarters', value: ModelsCoreStatisticsStatisticComparisons.Quarters },
        { label: 'Months', value: ModelsCoreStatisticsStatisticComparisons.Months },
        { label: 'Recent Rounds', value: ModelsCoreStatisticsStatisticComparisons.RecentRounds }
    ];
    golfRoundTypes: SelectItem[] = [
        { label: 'All', value: ModelsCoreRoundsGolfRoundTypes.All },
        { label: 'Competitive', value: ModelsCoreRoundsGolfRoundTypes.Competitive },
        { label: 'Practice', value: ModelsCoreRoundsGolfRoundTypes.Practice }
    ];
    charts: SelectItem[] = [
        { value: ModelsStatisticsGetChartStatisticCharts.MissTendencyOfFairways, label: 'Miss Tendency of Fairways' },
        { value: ModelsStatisticsGetChartStatisticCharts.BadMissTendencyOfFairways, label: 'Bad Miss Tendency of Fairways' },
        { value: ModelsStatisticsGetChartStatisticCharts.PenaltyMissTendencyOfFairways, label: 'Penalty Miss Tendency of Fairways' },
        { value: ModelsStatisticsGetChartStatisticCharts.MissTendencyOfApproachShots, label: 'Miss Tendency of Approach Shots' },
        { value: ModelsStatisticsGetChartStatisticCharts.BadMissTendencyOfApproachShots, label: 'Bad Miss Tendency of Approach Shots' },
        {
            value: ModelsStatisticsGetChartStatisticCharts.PenaltyMissTendencyOfApproachShots,
            label: 'Penalty Miss Tendency of Approach Shots'
        },
        { value: ModelsStatisticsGetChartStatisticCharts.AvgProximityToHoleByShotType, label: 'Proximity to Hole by Shot Type' },
        { value: ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForChips, label: 'Proximity to Hole for Chip Shots' },
        { value: ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForPitches, label: 'Proximity to Hole for Pitch Shots' },
        { value: ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForBunkerShots, label: 'Proximity to Hole for Bunker Shots' },
        { value: ModelsStatisticsGetChartStatisticCharts.AvgPuttsByStartingDistance, label: 'Average Putts by Starting Distance' },
        { value: ModelsStatisticsGetChartStatisticCharts.PercentOf1PuttsByStartingDistance, label: '1 Putt % by Starting Distance' },
        { value: ModelsStatisticsGetChartStatisticCharts.PercentOf3PuttsByStartingDistance, label: '3 Putt % by Starting Distance' }
    ];
    private golfRoundCharts: SelectItem[] = [
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundScoringAverages, label: 'Scoring Average' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundScores, label: 'Scores' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundFairways, label: 'Fairways' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundGIRs, label: 'GIRs' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundPutts, label: 'Putts' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundPenalties, label: 'Penalties' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundRunningStrokes, label: 'Running Strokes' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundRunningScore, label: 'Running Score' },
        { value: ModelsStatisticsGetChartStatisticCharts.GolfRoundScoreByHole, label: 'Score by Hole' }
    ];
    private nonGolfRoundCharts: SelectItem[] = [
        { value: ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleOrder, label: 'Average Performance by Hole Order' },
        { value: ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleHandicap, label: 'Average Performance by Hole Handicap' },
        { value: ModelsStatisticsGetChartStatisticCharts.CoursesPlayed, label: 'Courses Played' }
    ];

    get viewingGolfRound() {
        return this.golfRoundId !== undefined;
    }

    get shouldShowGolfers() {
        return !this.viewingGolfRound
            && (this.args.comparison === ModelsCoreStatisticsStatisticComparisons.MostRecentYears
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Quarters
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Months
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.RecentRounds
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.GolfRoundImpact
            || this.args.chart === ModelsStatisticsGetChartStatisticCharts.CoursesPlayed
            || this.args.chart === ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleOrder
            || this.args.chart === ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleHandicap);
    }

    get shouldShowYears() {
        return !this.viewingGolfRound
            && (this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Friends
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Quarters
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Months);
    }

    get shouldShowGolfRoundTypes() {
        return !this.viewingGolfRound
            && (this.args.comparison === ModelsCoreStatisticsStatisticComparisons.MostRecentYears
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Quarters
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Months
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.RecentRounds
            || this.args.comparison === ModelsCoreStatisticsStatisticComparisons.Friends);
    }

    get shouldShowComparisons() {
        return !this.viewingGolfRound
            && (this.args.chart !== ModelsStatisticsGetChartStatisticCharts.CoursesPlayed
            && this.args.chart !== ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleOrder
            && this.args.chart !== ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleHandicap);
    }

    get showMissTendencyChart() {
        return this.isChart(
            ModelsStatisticsGetChartStatisticCharts.MissTendencyOfFairways,
            ModelsStatisticsGetChartStatisticCharts.BadMissTendencyOfFairways,
            ModelsStatisticsGetChartStatisticCharts.PenaltyMissTendencyOfFairways,
            ModelsStatisticsGetChartStatisticCharts.MissTendencyOfApproachShots,
            ModelsStatisticsGetChartStatisticCharts.BadMissTendencyOfApproachShots,
            ModelsStatisticsGetChartStatisticCharts.PenaltyMissTendencyOfApproachShots);
    }

    get showPuttingByStartingDistanceChart() {
        return this.isChart(
            ModelsStatisticsGetChartStatisticCharts.AvgPuttsByStartingDistance,
            ModelsStatisticsGetChartStatisticCharts.PercentOf1PuttsByStartingDistance,
            ModelsStatisticsGetChartStatisticCharts.PercentOf3PuttsByStartingDistance);
    }

    get showShortGameProximityChart() {
        return this.isChart(
            ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForChips,
            ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForPitches,
            ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForBunkerShots);
    }

    get showProximityByShotChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.AvgProximityToHoleByShotType);
    }

    get isPercentagePuttingChart() {
        return this.isChart(
            ModelsStatisticsGetChartStatisticCharts.PercentOf1PuttsByStartingDistance,
            ModelsStatisticsGetChartStatisticCharts.PercentOf3PuttsByStartingDistance);
    }

    get showCoursesPlayedChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.CoursesPlayed);
    }

    get showScoreByHoleChart() {
        return this.isChart(
            ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleOrder,
            ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleHandicap);
    }

    get showGolfRoundScoringAverageChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundScoringAverages);
    }

    get showGolfRoundScoresChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundScores);
    }

    get showGolfRoundFairwaysChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundFairways);
    }

    get showGolfRoundGIRsChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundGIRs);
    }

    get showGolfRoundPuttsChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundPutts);
    }

    get showGolfRoundPenaltiesChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundPenalties);
    }

    get showGolfRoundRunningStrokesChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundRunningStrokes);
    }

    get showGolfRoundRunningScoreChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundRunningScore);
    }

    get showGolfRoundScoreByHoleChart() {
        return this.isChart(ModelsStatisticsGetChartStatisticCharts.GolfRoundScoreByHole);
    }

    ngOnInit(): void {
        if(this.viewingGolfRound) {
            this.charts = [...this.golfRoundCharts, ...this.charts];
            this.golferId = this.currentGolfer.golferId;
            this.args.golfRoundId = this.golfRoundId;
            this.args.chart = ModelsStatisticsGetChartStatisticCharts.GolfRoundScoringAverages;
            this.args.comparison = ModelsCoreStatisticsStatisticComparisons.GolfRound;

            this.loadChart();
        }
        else {
            this.charts = [...this.nonGolfRoundCharts, ...this.charts];
            this.golferLookups.getFriendAndYearsPlayedSelectItems()
                .pipe(this.takeUntilUnsubscribed())
                .subscribe(
                    lookups => {
                        this.golfers = lookups.friends;
                        this.yearsPlayed = lookups.yearsPlayed;

                        this.golferId = this.golferLookups.defaultFriendId;
                        this.args.year = this.golferLookups.defaultYearPlayed;
                        this.args.chart = ModelsStatisticsGetChartStatisticCharts.AvgPerformanceByHoleOrder;

                        this.loadChart();
                    });
        }
    }

    onChartChanged() {
        this.loadChart();
    }

    onComparisonChanged() {
        this.loadChart();
    }

    onGolfRoundTypeChanged() {
        this.loadChart();
    }

    onGolferChanged() {
        this.loadChart();
    }

    onYearChanged() {
        this.loadChart();
    }

    getShortGameProximityShotTypeLabel() {
        let label = 'Shots';

        switch(this.args.chart) {
            case ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForChips:
                label = 'Chip Shots';
                break;
            case ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForPitches:
                label = 'Pitch Shots';
                break;
            case ModelsStatisticsGetChartStatisticCharts.ProximityToHoleForBunkerShots:
                label = 'Bunker Shots';
                break;
        }

        return label;
    }

    getPuttCountLabel() {
        let label = 'Putts';

        switch(this.args.chart) {
            case ModelsStatisticsGetChartStatisticCharts.PercentOf1PuttsByStartingDistance:
                label = 'One Putts';
                break;
            case ModelsStatisticsGetChartStatisticCharts.PercentOf3PuttsByStartingDistance:
                label = 'Three Putts';
                break;
        }

        return label;
    }

    private isChart(...charts: ModelsStatisticsGetChartStatisticCharts[]) {
        for(let i = 0; i < charts.length; i++) {
            if(this.args.chart === charts[i]) {
                return true;
            }
        }

        return false;
    }

    private loadChart() {
        this.taskStarted();
        this.chartData = null;

        const chartObservable =  this.golfRoundId > 0
            ? this.roundsProxy.getChart(this.golfRoundId, this.args)
            : this.golfersProxy.getChart(this.golferId, this.args);

        chartObservable
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.response = response.body;
                    this.chartData = response.body.chart;
                });
    }
}
