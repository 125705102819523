import { Component } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-proximity-by-shot-line-chart',
    template: '<p-chart type="line" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class ProximityByShotLineChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'line');
        this.options.scales.y.beginAtZero = false;
        this.options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        this.options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
    }

    private getTooltipTitle() {
        return null;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const shotType = tooltipItem.label;
        const golfer = dataset.label;
        const proximity = data[tooltipItem.dataIndex];

        return `${golfer}: ${shotType} Average ${proximity} to the Pin`;
    }
}
