import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message, SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { CoursesControllerProxy, GolfersControllerProxy, RoundsControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersGolfer,
    ModelsCoreRoundsGolfRound,
    ModelsCoreSharedStateWithCities,
    ModelsWebApiCoursesGetCoursesArgs,
    ModelsWebApiRoundsCreateRoundModel,
    ModelsWebApiRoundsJoinRoundModel
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum CourseViewOption {
    favorites = 1,
    nearby = 2,
    cityState = 3
}

@Component({
    selector: 'my-new-round',
    templateUrl: './new-round.component.html',
    standalone: false
})
export class NewRoundComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private golfersProxy: GolfersControllerProxy,
        private roundsProxy: RoundsControllerProxy,
        private router: Router,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    messages: Message[] = [];
    round: ModelsWebApiRoundsCreateRoundModel = {
        teeTime: new Date(),
        courseId: 0,
        golferIds: []
    };
    courses: SelectItem[] = [];
    states: SelectItem[] = [];
    cities: SelectItem[] = [];
    favoriteCourses: SelectItem[] = [];
    nearbyCourses: SelectItem[] = [];
    golfers: SelectItem[] = [];
    viewOptions: SelectItem[] = [];
    existingGolfRounds: ModelsCoreRoundsGolfRound[] = [];
    selectedView = CourseViewOption.favorites;
    stateCode: string;
    city: string;
    ignoreExistingGolfRounds = false;
    existingGolfRoundsChecked = false;
    existingGolfRoundsMessage: string;
    private stateCodeToCityMap: { [stateCode: string]: SelectItem[] };

    ngOnInit(): void {
        this.checkForIncompleteGolfRoundsByFriends();
        this.loadViewOptions();
        this.loadGolfers();
        this.loadCourses();

        this.coursesProxy.getLocations()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const states = response.body;

                    this.loadStates(states);
                    this.loadCities();
                });
    }

    get isCityStateView() {
        return this.selectedView === CourseViewOption.cityState;
    }

    get showExistingGolfRounds() {
        return this.existingGolfRounds.length > 0 && !this.ignoreExistingGolfRounds;
    }

    get showGolfRoundCreation() {
        return !this.showExistingGolfRounds && this.existingGolfRoundsChecked;
    }

    onStateChanged() {
        this.loadCities();
        this.loadCourses();
    }

    onCityChanged() {
        this.loadCourses();
    }

    onViewChanged() {
        this.loadCourses();
    }

    continueWithNewRoundCreation() {
        this.ignoreExistingGolfRounds = true;
    }

    getGolferName(golfer: ModelsCoreGolfersGolfer) {
        return golfer.golferId === this.currentGolfer.golferId
            ? 'You'
            : `${golfer.firstName} ${golfer.lastName}`;
    }

    joinRound(golfRound: ModelsCoreRoundsGolfRound) {
        const model: ModelsWebApiRoundsJoinRoundModel = {
            golfRoundId: golfRound.golfRoundId,
            golferIds: [this.currentGolfer.golferId]
        };

        this.taskStarted();

        this.roundsProxy.joinRound(model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const golfRoundId = response.body.golfRoundId;
                    this.router.navigate(['rounds', golfRoundId, { starting: true }]);
                });
    }

    createRound(startRound = false) {
        this.taskStarted();

        this.roundsProxy.createRound(this.round)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const golfRoundId = response.body.golfRoundId;

                    if(startRound) {
                        this.router.navigate(['rounds', golfRoundId, { starting: true }]);
                    }
                    else {
                        window.history.back();
                    }
                });
    }

    isFormValid() {
        return this.round.teeTime !== undefined && this.round.courseId > 0 && this.round.golferIds.length > 0;
    }

    private checkForIncompleteGolfRoundsByFriends() {
        this.roundsProxy.getIncompleteGolfRoundsByFriends()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.existingGolfRounds = response.body;
                this.ignoreExistingGolfRounds = this.existingGolfRounds.length === 0;
                this.existingGolfRoundsChecked = true;
                this.setExistingGolfRoundsMessage();
            });
    }

    private setExistingGolfRoundsMessage() {
        if(this.existingGolfRounds.length > 0) {
            const count = this.existingGolfRounds.length;
            const roundDescription = count === 1 ? 'this round' : `one of these ${count} rounds`;
            this.existingGolfRoundsMessage = `Did you want to join ${roundDescription} instead of creating a new round?`;
        }
        else {
            this.existingGolfRoundsMessage = undefined;
        }
    }

    private loadViewOptions() {
        this.viewOptions.push(
            { value: CourseViewOption.favorites, label: 'Favorites' },
            { value: CourseViewOption.cityState, label: 'City/State' }
        );

        if(navigator.geolocation) {
            this.viewOptions.splice(1, 0, { value: CourseViewOption.nearby, label: 'Nearby' });
            this.selectedView = CourseViewOption.nearby;
        }
        else {
            this.selectedView = CourseViewOption.favorites;
        }
    }
    
    private loadCourses() {
        const args = {} as ModelsWebApiCoursesGetCoursesArgs;
        args.includeHistory = false;

        switch(this.selectedView) {
            case CourseViewOption.cityState:
                args.city = this.city;
                args.state = this.stateCode;
                this.loadCoursesByArgs(args);
                break;
            case CourseViewOption.nearby:
                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        position => {
                            args.latitude = position.coords.latitude;
                            args.longitude = position.coords.longitude;
                            this.loadCoursesByArgs(args);
                        });
                }
                else {
                    args.favorites = true;
                    this.selectedView = CourseViewOption.favorites;
                    this.loadCoursesByArgs(args);
                }
                break;
            default:
                args.favorites = true;
                this.loadCoursesByArgs(args);
                break;
        }
    }

    private loadCoursesByArgs(args: ModelsWebApiCoursesGetCoursesArgs) {
        return this.coursesProxy.getCourses(args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.courses = response.body.map(
                        course => {
                            const item = {
                                value: course.courseId
                            } as SelectItem;

                            if(course.milesAway === undefined) {
                                item.label = course.name;
                            }
                            else {
                                item.label = `${course.name} (${course.milesAway.toFixed(1)} miles away)`;
                            }

                            return item;
                        });
                });
    }

    private loadStates(states: ModelsCoreSharedStateWithCities[]) {
        this.states.length = 0;
        this.stateCodeToCityMap = {};

        states.forEach(state => {
            this.stateCodeToCityMap[state.code] = state.cities.map(
                name => {
                    return {
                        value: name,
                        label: name
                    } as SelectItem;
                });

            this.states.push(
                {
                    value: state.code,
                    label: state.name
                });
        });

        this.setDefaultStateCode();
    }

    private setDefaultStateCode() {
        const golfer = this.currentGolfer;

        let defaultStateCode = this.states.find(
            state => {
                return state.value === golfer.stateCode;
            });

        if(!defaultStateCode && this.states.length > 0) {
            defaultStateCode = this.states[0].value;
        }

        this.stateCode = defaultStateCode ? defaultStateCode.value : '';
    }

    private loadCities() {
        const citiesInCurrentState = this.stateCodeToCityMap[this.stateCode];
        this.cities.length = 0;

        if(citiesInCurrentState) {
            Array.prototype.push.apply(this.cities, citiesInCurrentState);
        }

        this.setDefaultCity();

        this.cities.splice(0, 0, { value: '', label: 'ALL' });
    }

    private setDefaultCity() {
        const golfer = this.currentGolfer;

        let defaultCity = this.cities.find(
            city => {
                return city.value === golfer.city;
            });

        if(!defaultCity && this.cities.length > 0) {
            defaultCity = this.cities[0];
        }

        this.city = defaultCity ? defaultCity.value : '';
    }

    private loadGolfers() {
        this.golfersProxy.getFriends(this.currentGolfer.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const golfers = response.body;
                    golfers.unshift(this.currentGolfer);

                    this.round.golferIds.push(this.currentGolfer.golferId);
                    this.golfers = golfers.map(
                        (golfer: ModelsCoreGolfersGolfer) => {
                            return {
                                value: golfer.golferId,
                                label: golfer.fullName
                            } as SelectItem;
                        });
                });
    }
}
