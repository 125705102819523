import { Component } from '@angular/core';
import { Message } from 'primeng/api';
import { IApiErrorResponse } from '../../server-proxies/index';

@Component({
    selector: 'my-messages',
    templateUrl: './messages.component.html',
    standalone: false
})
export class MessagesComponent {
    messages: Message[] = [];

    count() {
        return this.messages.length;
    }

    clear() {
        //Primeng messages component won't update if we just clear the existing array.
        this.messages = [];
    }

    clearInMilliseconds(timeout: number, onComplete?: () => void) {
        setTimeout(() => {
            this.clear();

            if(onComplete) {
                onComplete();
            }
        }, timeout);
    }

    showSuccess(detail: string, summary?: string) {
        this.showMessage('success', detail, summary);
    }

    showInfo(detail: string, summary?: string) {
        this.showMessage('info', detail, summary);
    }

    showWarning(detail: string, summary?: string) {
        this.showMessage('warn', detail, summary);
    }

    showError(detail: string, summary?: string) {
        this.showMessage('error', detail, summary);
    }

    showApiError(response: IApiErrorResponse) {
        let message = `An error occurred. (${response.status})`;

        if(response.error) {
            message = response.error.detail;
        }

        this.showError(message);
        this.clearInMilliseconds(5000);
    }

    private showMessage(severity: string, detail: string, summary?: string) {
        const message: Message = {
            severity: severity,
            detail: detail,
            summary: summary
        };

        //We have to replace the array instead of doing a push because the primeng messages component doesn't update.
        this.messages = this.messages.concat([message]);
    }
}
