import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy, ReferenceControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';

import {
    ModelsCorePracticeImprovementPlan,
    ModelsCoreStatisticsStatistic,
    ModelsWebApiGolfersSaveImprovementPlanModel
} from '../../shared/swagger-codegen/models';

interface ModelsCorePracticeImprovementPlanWithStatisticKey extends ModelsCorePracticeImprovementPlan {
    statisticKey: string;
}

@Component({
    selector: 'my-improvement-plan',
    templateUrl: './improvement-plan.component.html',
    styleUrls: [
        './improvement-plan.component.scss'
    ],
    standalone: false
})
export class ImprovementPlanComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    @ViewChild(MessagesComponent) messages: MessagesComponent;
    improvementPlans: ModelsCorePracticeImprovementPlanWithStatisticKey[] = [];
    statistics: SelectItem[] = [];
    title = 'Improvement Plan';
    private golferId: number;

    ngOnInit() {
        this.golferId = this.currentGolfer.golferId;
        this.loadImprovementPlans();
        this.loadStatistics();
    }

    save() {
        const models = this.mapToSaveModels(this.improvementPlans);

        this.golfersControllerProxy.saveImprovementPlans(this.golferId, models)
            .subscribe(() => {
                this.messages.showSuccess('Improvement Plan Saved.');
                this.messages.clearInMilliseconds(2000);
            },
            response => this.messages.showApiError(response));
    }

    private loadImprovementPlans() {
        this.taskStarted();
        this.golfersControllerProxy.getImprovementPlans(this.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const improvementPlans = this.mapWithStatisticKey(response.body);

                    while(improvementPlans.length < 5) {
                        improvementPlans.push({} as ModelsCorePracticeImprovementPlanWithStatisticKey);
                    }

                    this.improvementPlans = improvementPlans;
                });
    }

    private mapWithStatisticKey(improvementPlans: ModelsCorePracticeImprovementPlan[]) {
        return improvementPlans.map((plan: ModelsCorePracticeImprovementPlanWithStatisticKey) => {
            plan.statisticKey = this.createStatisticKey(plan.statistic.statisticId, plan.holesPlayed);
            return plan;
        });
    }

    private mapToSaveModels(improvementPlans: ModelsCorePracticeImprovementPlanWithStatisticKey[]) {
        return improvementPlans.map(plan => {
            const parsedKey = this.parseStatisticKey(plan.statisticKey);

            const model: ModelsWebApiGolfersSaveImprovementPlanModel = {
                statisticId: parsedKey.statisticId,
                holesPlayed: parsedKey.holesPlayed,
                level1: plan.level1,
                level2: plan.level2,
                level3: plan.level3,
                level4: plan.level4,
                level5: plan.level5
            };

            return model;
        });
    }

    private loadStatistics() {
        this.referenceControllerProxy.getStatistics()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.loadStatisticSelectItems(response.body);
                });
    }

    private loadStatisticSelectItems(statistics: ModelsCoreStatisticsStatistic[]) {
        statistics = statistics.sort((a, b) => {
            if(a.displayName < b.displayName) {
                return -1;
            }
            else if(a.displayName > b.displayName) {
                return 1;
            }
            else {
                return 0;
            }
        });

        const items: SelectItem[] = [{ value: '', label: '' }];

        for(let i = 0; i < statistics.length; i++) {
            const statistic = statistics[i];

            if(statistic.dependsOnHolesPlayed) {
                items.push({
                    value: this.createStatisticKey(statistic.statisticId, 18),
                    label: statistic.displayNamesByHolesPlayed[18] || statistic.displayName
                });
                items.push({
                    value: this.createStatisticKey(statistic.statisticId, 9),
                    label: statistic.displayNamesByHolesPlayed[9] || statistic.displayName
                });
            }
            else {
                items.push({ value: this.createStatisticKey(statistic.statisticId, 0), label: statistic.displayName });
            }
        }

        this.statistics = items;
    }

    private createStatisticKey(statisticId: number, holesPlayed: number) {
        return `${statisticId}:${holesPlayed}`;
    }

    private parseStatisticKey(key: string) {
        const split = key.split(':');

        return {
            statisticId: parseInt(split[0], 10),
            holesPlayed: parseInt(split[1], 10)
        };
    }
}
