import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersFriendStatus,
    ModelsCoreGolfersGolfer,
    ModelsCoreSharedStateWithCities,
    ModelsWebApiGolfersGetGolfersArgs
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum GolferViewOption {
    friends = 1,
    cityState = 2
}

@Component({
    selector: 'my-golfer-list',
    templateUrl: './golfer-list.component.html',
    standalone: false
})
export class GolferListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    golfers: ModelsCoreGolfersGolfer[] = [];
    states: SelectItem[] = [];
    cities: SelectItem[] = [];
    viewOptions: SelectItem[] = [
        { value: GolferViewOption.friends, label: 'Friends' },
        { value: GolferViewOption.cityState, label: 'City/State' }
    ];
    selectedView = GolferViewOption.friends;
    stateCode: string;
    city: string;
    includeInactive = false;
    private stateCodeToCityMap: { [stateCode: string]: SelectItem[] };

    ngOnInit(): void {
        this.taskStarted();

        this.golfersProxy.getLocations()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const states = response.body;

                    this.loadStates(states);
                    this.loadCities();
                    this.loadGolfers();
                });
    }

    get isCityStateView() {
        return this.selectedView === GolferViewOption.cityState;
    }

    get isAdmin() {
        return this.currentGolfer.isAdmin;
    }

    onStateChanged() {
        this.loadCities();
        this.loadGolfers();
    }

    onCityChanged() {
        this.loadGolfers();
    }

    onViewChanged() {
        this.loadGolfers();
    }

    onIncludeInactiveChange() {
        this.loadGolfers();
    }
    
    canFriend(golfer: ModelsCoreGolfersGolfer) {
        return golfer.friendStatus === ModelsCoreGolfersFriendStatus.None;
    }

    isPendingFriend(golfer: ModelsCoreGolfersGolfer) {
        return golfer.friendStatus === ModelsCoreGolfersFriendStatus.PendingFriend;
    }

    isFriend(golfer: ModelsCoreGolfersGolfer) {
        return golfer.friendStatus === ModelsCoreGolfersFriendStatus.Friend;
    }

    sendFriendRequest(golfer: ModelsCoreGolfersGolfer) {
        this.golfersProxy.addFriendRequest(this.currentGolfer.golferId, golfer.golferId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    golfer.friendStatus = ModelsCoreGolfersFriendStatus.PendingFriend;
                });
    }

    canEditGolfer(golfer: ModelsCoreGolfersGolfer) {
        return golfer.golferId === this.currentGolfer.golferId || this.currentGolfer.isAdmin;
    }
    
    private loadStates(states: ModelsCoreSharedStateWithCities[]) {
        this.states.length = 0;
        this.stateCodeToCityMap = {};

        states.forEach(state => {
            this.stateCodeToCityMap[state.code] = state.cities.map(
                name => {
                    return {
                        value: name,
                        label: name
                    } as SelectItem;
                });

            this.states.push(
                {
                    value: state.code,
                    label: state.name
                });
        });

        this.setDefaultStateCode();
    }

    private setDefaultStateCode() {
        const golfer = this.currentGolfer;

        let defaultStateCode = this.states.find(
            state => {
                return state.value === golfer.stateCode;
            });

        if(!defaultStateCode && this.states.length > 0) {
            defaultStateCode = this.states[0].value;
        }

        this.stateCode = defaultStateCode ? defaultStateCode.value : '';
    }

    private loadCities() {
        const citiesInCurrentState = this.stateCodeToCityMap[this.stateCode];
        this.cities.length = 0;

        if(citiesInCurrentState) {
            Array.prototype.push.apply(this.cities, citiesInCurrentState);
        }

        this.setDefaultCity();
    }

    private setDefaultCity() {
        const golfer = this.currentGolfer;

        let defaultCity = this.cities.find(
            city => {
                return city.value === golfer.city;
            });

        if(!defaultCity && this.cities.length > 0) {
            defaultCity = this.cities[0];
        }

        this.city = defaultCity ? defaultCity.value : '';
    }

    private loadGolfers() {
        const args = {} as ModelsWebApiGolfersGetGolfersArgs;
        args.includeInactive = this.includeInactive;

        if(this.selectedView === GolferViewOption.cityState) {
            args.state = this.stateCode;
            args.city = this.city;
        }
        else {
            args.friends = true;
        }

        return this.golfersProxy.getGolfers(args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.golfers = response.body;
                });
    }
}
