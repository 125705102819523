import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { GolfersControllerProxy } from '../../../shared/server-proxies';
import { GolferLookupService } from '../../../shared/golfers';
import { ScorecardDialogService } from '../../../shared/rounds/scorecard-dialog/scorecard-dialog.service';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

import {
    ModelsGolfersGetHandicapsGetHandicapsResponse,
    ModelsGolfersGetHandicapsHandicapGolfRoundGolfer,
    ModelsGolfersGetHandicapsHandicapScore
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-handicap-differentials',
    templateUrl: './handicap-differentials.component.html',
    styleUrls: [
        './handicap-differentials.component.scss'
    ],
    standalone: false
})
export class HandicapDifferentialsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private golferLookups: GolferLookupService,
        private scorecardDialog: ScorecardDialogService,
        private currentRoute: ActivatedRoute) {
        super();
    }

    response: ModelsGolfersGetHandicapsGetHandicapsResponse;
    golferId: number;
    includedScoreCount: number;
    recentScoreCount: number;
    recentlyReplacedScoreCount: number;


    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const golferIdParam = params['golferId'];
                    this.golferId = parseInt(golferIdParam, 10) || this.golferLookups.defaultFriendId;
                    this.loadHandicaps();
                });
    }

    showScorecardDialog(golfRoundGolfer: ModelsGolfersGetHandicapsHandicapGolfRoundGolfer) {
        this.scorecardDialog.open(golfRoundGolfer.golfRoundId, golfRoundGolfer.teeTime, golfRoundGolfer.courseName);
    }

    getRowCss(scoringRecord: ModelsGolfersGetHandicapsHandicapScore, recordOrdinal: number) {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'included-in-handicap': scoringRecord.includedInHandicap,
            'replaced-scoring-record': recordOrdinal > 20
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    private loadHandicaps() {
        this.taskStarted();
        this.golfersProxy.getHandicaps(this.golferId)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.response = response.body;
                    this.setScoreCounts();
                });
    }

    private setScoreCounts() {
        this.includedScoreCount = 0;
        this.recentScoreCount = 0;
        this.recentlyReplacedScoreCount = 0;

        this.response.handicap.handicapScores.forEach((s, i) => {
            if(i < 20) {
                this.recentScoreCount++;

                if(s.includedInHandicap) {
                    this.includedScoreCount++;
                }
            }
            else {
                this.recentlyReplacedScoreCount++;
            }
        });
    }
}
