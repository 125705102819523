import { Component, OnInit, Input } from '@angular/core';
import { ScorecardCalculatorService } from '../../../shared/rounds/scorecard/scorecard-calculator.service';

import {
    ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard,
    ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-scorecard-quick-glance',
    templateUrl: './scorecard-quick-glance.component.html',
    standalone: false
})
export class ScorecardQuickGlanceComponent implements OnInit {
    constructor(private calculator: ScorecardCalculatorService) {
    }

    @Input()
    scorecard: ModelsRoundsGetGolfRoundScorecardGolfRoundScorecard;

    ngOnInit() {

    }

    getProjectedScore(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getProjectedScore(golfer.front9, golfer.back9);
    }

    getPar3ScoringAverage(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getPar3ScoringAverage(golfer.front9, golfer.back9);
    }

    getPar4ScoringAverage(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getPar4ScoringAverage(golfer.front9, golfer.back9);
    }

    getPar5ScoringAverage(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getPar5ScoringAverage(golfer.front9, golfer.back9);
    }

    getProjectedBogeyOutScore(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getProjectedBogeyOutScore(golfer.front9, golfer.back9);
    }

    getProjectedParOutScore(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getProjectedParOutScore(golfer.front9, golfer.back9);
    }

    getProjectedBirdieOutScore(golfer: ModelsRoundsGetGolfRoundScorecardScorecardGolfRoundGolfer) {
        return this.calculator.getProjectedBirdieOutScore(golfer.front9, golfer.back9);
    }
}
