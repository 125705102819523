import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { CoursesControllerProxy, GolfersControllerProxy } from '../../shared/server-proxies';
import { CurrentGolferService } from '../../shared/golfers/current-golfer.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreCoursesCourse,
    ModelsCoreSharedStateWithCities,
    ModelsWebApiCoursesGetCoursesArgs
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum CourseViewOption {
    favorites = 1,
    nearby = 2,
    cityState = 3
}

@Component({
    selector: 'my-course-list',
    templateUrl: './course-list.component.html',
    styleUrls: [
        './course-list.component.scss'
    ],
    standalone: false
})
export class CourseListComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private coursesProxy: CoursesControllerProxy,
        private golfersProxy: GolfersControllerProxy,
        private router: Router,
        private currentGolfer: CurrentGolferService) {
        super();
    }

    courses: ModelsCoreCoursesCourse[] = [];
    states: SelectItem[] = [];
    cities: SelectItem[] = [];
    viewOptions: SelectItem[] = [];
    selectedView = CourseViewOption.favorites;
    stateCode: string;
    city: string;
    private position: GeolocationPosition;
    private stateCodeToCityMap: { [stateCode: string]: SelectItem[] };
    private favoriteCourseIds: { [courseId: number]: boolean } = {};

    ngOnInit(): void {
        this.loadFavoriteCourses();
        this.loadViewOptions();

        this.taskStarted();
        this.coursesProxy.getLocations()
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const states = response.body;

                    this.loadStates(states);
                    this.loadCities();
                    this.loadCourses();
                });
    }

    get isCityStateView() {
        return this.selectedView === CourseViewOption.cityState;
    }

    onStateChanged() {
        this.loadCities();
        this.loadCourses();
    }

    onCityChanged() {
        this.loadCourses();
    }

    onViewChanged() {
        if(this.selectedView === CourseViewOption.nearby) {
            this.position = undefined;
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.position = position;
                        this.loadCourses();
                    },
                    () => {
                        this.selectedView = CourseViewOption.favorites;
                        this.loadCourses();
                    });
            }
            else {
                this.selectedView = CourseViewOption.favorites;
                this.loadCourses();
            }
        }
        else {
            this.loadCourses();
        }
    }

    viewHistory(course: ModelsCoreCoursesCourse) {
        this.router.navigate(['courses', course.courseId, 'history']);
    }

    isFavoriteCourse(course: ModelsCoreCoursesCourse) {
        return this.favoriteCourseIds[course.courseId] !== undefined;
    }

    addFavoriteCourse(course: ModelsCoreCoursesCourse) {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.addFavoriteCourse(golferId, course.courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.favoriteCourseIds[course.courseId] = true;
            });
    }

    removeFavoriteCourse(course: ModelsCoreCoursesCourse) {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.removeFavoriteCourse(golferId, course.courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                delete this.favoriteCourseIds[course.courseId];
            });
    }

    has18HoleHistory(course: ModelsCoreCoursesCourse) {
        return course
            && course.history
            && course.history.historyFor18Holes;
    }

    getNoCoursesMessage() {
        let message = 'No courses.';

        switch(this.selectedView) {
            case CourseViewOption.favorites:
                message = 'No favorite courses.';
                break;
            case CourseViewOption.nearby:
                message = 'No courses nearby.';
                break;
            case CourseViewOption.cityState:
                message = 'No courses in this location.';
                break;
        }

        return this.getEmptyMessage(message);
    }

    private loadViewOptions() {
        this.viewOptions.push(
            { value: CourseViewOption.favorites, label: 'Favorites' },
            { value: CourseViewOption.cityState, label: 'City/State' }
        );

        if(navigator.geolocation) {
            this.viewOptions.splice(1, 0, { value: CourseViewOption.nearby, label: 'Nearby' });
        }
    }

    private loadFavoriteCourses() {
        const golferId = this.currentGolfer.golferId;
        this.golfersProxy.getFavoriteCourses(golferId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    response.body.forEach(
                        course => {
                            this.favoriteCourseIds[course.courseId] = true;
                        });
                });
    }

    private loadStates(states: ModelsCoreSharedStateWithCities[]) {
        this.states.length = 0;
        this.stateCodeToCityMap = {};

        states.forEach(state => {
            this.stateCodeToCityMap[state.code] = state.cities.map(
                name => {
                    return {
                        value: name,
                        label: name
                    } as SelectItem;
                });

            this.states.push(
                {
                    value: state.code,
                    label: state.name
                });
        });

        this.setDefaultStateCode();
    }

    private setDefaultStateCode() {
        const golfer = this.currentGolfer;

        let defaultStateCode = this.states.find(
            state => {
                return state.value === golfer.stateCode;
            });

        if(!defaultStateCode && this.states.length > 0) {
            defaultStateCode = this.states[0].value;
        }

        this.stateCode = defaultStateCode ? defaultStateCode.value : '';
    }

    private loadCities() {
        const citiesInCurrentState = this.stateCodeToCityMap[this.stateCode];
        this.cities.length = 0;

        if(citiesInCurrentState) {
            Array.prototype.push.apply(this.cities, citiesInCurrentState);
        }

        this.setDefaultCity();

        this.cities.splice(0, 0, { value: '', label: 'ALL' });
    }

    private setDefaultCity() {
        const golfer = this.currentGolfer;

        let defaultCity = this.cities.find(
            city => {
                return city.value === golfer.city;
            });

        if(!defaultCity && this.cities.length > 0) {
            defaultCity = this.cities[0];
        }

        this.city = defaultCity ? defaultCity.value : '';
    }

    private loadCourses() {
        const args = {} as ModelsWebApiCoursesGetCoursesArgs;
        args.includeHistory = true;

        switch(this.selectedView) {
            case CourseViewOption.cityState:
                args.city = this.city;
                args.state = this.stateCode;
                break;
            case CourseViewOption.nearby:
                if(this.position) {
                    args.latitude = this.position.coords.latitude;
                    args.longitude = this.position.coords.longitude;
                }
                else {
                    args.favorites = true;
                }
                break;
            default:
                args.favorites = true;
                break;
        }

        return this.coursesProxy.getCourses(args)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.courses = response.body;
                });
    }
}
