import { Component } from '@angular/core';
import { ChartService } from './chart.service';
import { ChartBaseDirective } from './chart-base.directive';

@Component({
    selector: 'my-score-name-bar-chart',
    template: '<p-chart type="bar" [data]="data" [options]="options" responsive="true"></p-chart>',
    standalone: false
})
export class ScoreNameBarChartComponent extends ChartBaseDirective {
    constructor(chartService: ChartService) {
        super(chartService, 'bar');
    }
}
