import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AuthenticationControllerProxy } from '../../shared/server-proxies';
import { SessionService } from '../../shared/auth/session.service';
import { InstrumentationService } from '../../shared/core/instrumentation.service';
import { MessagePubSubService } from '../../shared/core/message-pub-sub.service';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsGolfersAuthenticateAuthenticateResponse,
    ModelsWebApiAuthenticationLoginModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-login',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.scss'
    ],
    standalone: false
})
export class LoginComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private authenticationControllerProxy: AuthenticationControllerProxy,
        private instrumentationService: InstrumentationService,
        private messagePubSub: MessagePubSubService,
        private session: SessionService,
        private router: Router) {
        super();
    }

    @ViewChild(MessagesComponent) messages: MessagesComponent;
    credentials: ModelsWebApiAuthenticationLoginModel = {
        email: '',
        password: '',
        rememberMe: false
    };
    hasRememberMe: boolean;
    isLoggingInWithToken: boolean;

    ngOnInit() {
        const token = this.session.getPersistedToken();

        if(token) {
            this.loginWithToken(token);
        }
    }

    getEmailAutoFocus() {
        return this.hasRememberMe ? null : 'autofocus';
    }

    getPasswordAutoFocus() {
        return this.hasRememberMe ? 'autofocus' : null;
    }

    login() {
        this.taskStarted();
        this.messages.clear();

        this.authenticationControllerProxy.login(this.credentials)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.messages.showSuccess('Login succeeded.');
                    this.handleSuccessfulLogin(response.body, this.credentials.rememberMe);
                },
                error => {
                    if(error.status === 401) {
                        this.messages.showWarning('Login failed.');
                    }
                    else {
                        this.messages.showError(`An error occurred. (${error.status})`);
                    }
                });
    }

    private loginWithToken(token: string) {
        this.taskStarted();
        this.isLoggingInWithToken = true;

        this.authenticationControllerProxy.loginWithToken({ token: token })
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.handleSuccessfulLogin(response.body, true);
                },
                error => {
                    if(error.status === 401) {
                        this.session.disposeSession();
                    }

                    this.isLoggingInWithToken = false;
                });
    }

    private handleSuccessfulLogin(result: ModelsGolfersAuthenticateAuthenticateResponse, rememberMe: boolean) {
        this.session.initializeSession(result.golfer, result.token.value, rememberMe);
        this.instrumentationService.setUser(result.golfer);

        this.messagePubSub.publishMessage(
            {
                id: 'golfers:user-logged-in',
                content: result.golfer
            });
        this.router.navigateByUrl('/');
    }
}
