import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { GolfersControllerProxy } from '../../shared/server-proxies';
import { MessagesComponent } from '../../shared/ui/messages/messages.component';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreGolfersGolfer
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-golfer-profile-tab-view',
    templateUrl: './golfer-profile-tab-view.component.html',
    standalone: false
})
export class GolferProfileTabViewComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersControllerProxy: GolfersControllerProxy,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    activeTabIndex: number;
    golfer: ModelsCoreGolfersGolfer;
    private golferId: number;

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.activeTabIndex = parseInt(params['tab'], 10) || 0;
                    this.golferId = parseInt(params['golferId'], 10) || 0;

                    this.golfersControllerProxy.getGolfer(this.golferId)
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            response => {
                                this.golfer = response.body;
                            },
                            response => this.messages.showApiError(response));
                });
    }

    onActiveTabChanged(e: any) {
        const params = {
            tab: e.index
        };

        this.router.navigate(['/golfers', this.golferId, params]);
    }
}
