import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { MessagesComponent } from '../../../shared/ui/messages/messages.component';
import { GolfersControllerProxy } from '../../../shared/server-proxies/golfers-controller-proxy.service';

import {
    ModelsWebApiGolfersChangePasswordModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-change-password',
    templateUrl: './change-password.component.html',
    standalone: false
})
export class ChangePasswordComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @ViewChild(MessagesComponent, { static: true }) messages: MessagesComponent;
    model = {} as ModelsWebApiGolfersChangePasswordModel;
    private golferId: number;

    ngOnInit() {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.golferId = parseInt(params['golferId'], 10) || 0;
                });
    }

    save() {
        this.taskStarted();
        this.golfersProxy.changePassword(this.golferId, this.model)
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.messages.showSuccess('Your password has been changed.');
                    this.messages.clearInMilliseconds(3000, () => this.router.navigate(['/golfers', this.golferId]));
                },
                response => {
                    this.messages.showApiError(response);
                    this.messages.clearInMilliseconds(5000);
                });
    }
}
