import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { GolfersControllerProxy, CoursesControllerProxy, ReferenceControllerProxy } from '../../shared/server-proxies';
import { GolferLookupService, IGolferSelectItem } from '../../shared/golfers/golfer-lookup.service';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import {
    ModelsCoreRoundsGolfRoundGolfer,
    ModelsCoreRoundsGolfRoundGolferHole,
    ModelsCoreStatisticsMetric
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-hole-history',
    templateUrl: './hole-history.component.html',
    standalone: false
})
export class HoleHistoryComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private golfersProxy: GolfersControllerProxy,
        private coursesProxy: CoursesControllerProxy,
        private referenceProxy: ReferenceControllerProxy,
        private golferLookups: GolferLookupService,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    holeHistory: ModelsCoreRoundsGolfRoundGolfer[] = [];
    golfers: IGolferSelectItem[];
    holes: SelectItem[] = [];
    yearsPlayed: SelectItem[] = [];
    year: number;
    golferId: number;
    holeId: number;
    private courseId: number;
    private metrics: { [key: string]: ModelsCoreStatisticsMetric } = {};

    ngOnInit(): void {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const courseId = parseInt(params['courseId'], 10);
                    this.holeId = parseInt(params['holeId'], 10);

                    if(this.courseId === courseId) {
                        this.loadHoleHistory();
                    }
                    else {
                        this.courseId = courseId;

                        this.loadMetrics();
                        this.loadHoles();
                        this.loadFriends()
                            .pipe(this.takeUntilUnsubscribed())
                            .subscribe(() => {
                                this.loadYearsPlayed(this.golferId)
                                    .pipe(this.takeUntilUnsubscribed())
                                    .subscribe(() => {
                                        this.loadHoleHistory();
                                    });
                            });
                    }
                });
    }

    onGolferChanged() {
        this.loadYearsPlayed(this.golferId)
            .subscribe(() => {
                this.loadHoleHistory();
            });
    }

    onYearChanged() {
        this.loadHoleHistory();
    }

    onHoleChanged() {
        this.router.navigate(['/courses', this.courseId, 'holes', this.holeId, 'history']);
    }

    getScoreCssClass(hole: ModelsCoreRoundsGolfRoundGolferHole) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            ace: hole.strokes === 1,
            eagle: hole.score === -2,
            birdie: hole.score === -1,
            par: hole.score === 0,
            bogey: hole.score === 1,
            'double-bogey': hole.score === 2,
            'triple-bogey': hole.score === 3,
            'quad-bogey': hole.score >= 4
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        return css;
    }

    getMetricCssClass(metricValue: number, metricName: string) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const css: { [key: string]: boolean } = {
            'great-range': false,
            'good-range': false,
            'bad-range': false
        };
        /* eslint-enable @typescript-eslint/naming-convention */
        
        if(this.metrics.hasOwnProperty(metricName)) {
            const metric: any = this.metrics[metricName];

            if(metric.greatRangeFrom && metric.greatRangeTo) {
                css['great-range'] = metricValue >= metric.greatRangeFrom && metricValue <= metric.greatRangeTo;
            }

            if(metric.goodRangeFrom && metric.goodRangeTo) {
                css['good-range'] = metricValue >= metric.goodRangeFrom && metricValue <= metric.goodRangeTo;
            }

            if(metric.badRangeFrom && metric.badRangeTo) {
                css['bad-range'] = metricValue >= metric.badRangeFrom && metricValue <= metric.badRangeTo;
            }
        }

        return css;
    }

    private loadMetrics() {
        this.referenceProxy.getMetrics()
            .subscribe(response => {
                response.body.forEach(m => {
                    const key = m.name.charAt(0).toLowerCase() + m.name.substr(1);
                    this.metrics[key] = m;
                });
            });
    }

    private loadFriends() {
        return this.golferLookups.getFriendSelectItems()
            .pipe(
                tap(
                    friends => {
                        this.golferId = this.golferLookups.defaultFriendId;
                        this.golfers = friends;
                    }
                )
            );
    }

    private loadHoles() {
        this.coursesProxy.getHoles(this.courseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.holes = response.body.map(h => ({ value: h.holeId, label: h.description }));
            });
    }

    private loadYearsPlayed(golferId: number) {
        return this.golfersProxy.getYearsPlayed(golferId, { courseId: this.courseId })
            .pipe(
                tap(
                    response => {
                        const years = response.body;
                        this.yearsPlayed = years.map(y => ({ value: y, label: y.toString() }));

                        const i = years.indexOf(this.year);

                        if(i < 0) {
                            this.year = years.length > 0 ? years[0] : 0;
                        }
                    }
                )
            );
    }

    private loadHoleHistory() {
        this.taskStarted();
        this.golfersProxy.getHoleHistory(this.golferId, this.holeId, { year: this.year })
            .pipe(
                finalize(() => this.taskCompleted()),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.holeHistory = response.body;
                });
    }
}
